import React from 'react'
import 'nodelist-foreach-polyfill';
import {
    isSafari,
    isMacOs, isIE, isIOS, isEdge, isFirefox, isMobile
} from "react-device-detect";
import CustomScroll from 'react-custom-scroll';

require('es6-promise').polyfill();
require('isomorphic-fetch');


export default class Modals extends React.Component {
    _isMounted = false;

    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            submit: false,
            loading: false,
            modal__subscribe: false,
            modal__team_1: false,
            modal__team_2: false
        }
    }

    componentDidMount() {

    }

    modalClose = () => {
        document.body.classList.remove('overflow-disabled');
        let data = this.state.data;

        this.setState({
            modal__subscribe: false,
            modal__team_1: false,
            modal__team_2: false,
        });
    }

    team1Show = () => {
        this.modalClose();
        this.setState({
            modal__team_1: true
        }, ()=>{
            document.body.classList.add('overflow-disabled');
        })
    }

    team2Show = () => {
        this.modalClose();
        this.setState({
            modal__team_2: true
        }, ()=>{
            document.body.classList.add('overflow-disabled');
        })
    }

    subscribeShow = () => {
        this.modalClose();
        this.setState({
            modal__subscribe: true
        }, ()=>{
            document.body.classList.add('overflow-disabled');
        })
    }

    render() {
        return (
            <div className="modals__list">
                <div data-show={this.state.modal__subscribe}  className="modal__custom modal__custom-subscribe">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                <path d="M0.293,1.707 L1.707,0.293 L29.707,28.293 L28.293,29.707 L0.293,1.707 Z"/>
                                <path d="M28.293,0.293 L29.707,1.707 L1.707,29.707 L0.293,28.293 L28.293,0.293 Z"/>
                            </svg>
                        </div>

                        <div className="modal__box-content">
                            <div className="modal__box-header">
                                <div className="modal__box-title">
                                    Subscribe
                                </div>
                            </div>
                            <div className="subscribe__form">
                                <div className="form__list">
                                    <div className="form__it">
                                        <div className="form__field">
                                            <input type="text" placeholder="Name*"/>
                                        </div>
                                    </div>
                                    <div className="form__it">
                                        <div className="form__field">
                                            <input type="text" placeholder="Email*"/>
                                        </div>
                                    </div>
                                    <div className="form__it">
                                        <div className="checkbox__wrap">
                                            <label>
                                                <input type="checkbox"/>
                                                <span className="checkbox__decor"/>
                                                <span className="checkbox__title">
                                                    I agree to the processing of my personal data
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form__it">
                                        <div className="form__hint text-left">
                                            Required fields are marked with an asterisk (*)
                                        </div>
                                    </div>
                                    <div className="form__it">
                                        <button type="submit" className="form__submit d-flex align-items-center justify-content-center">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__team_1}  className="modal__custom modal__custom-team">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                <path d="M0.293,1.707 L1.707,0.293 L29.707,28.293 L28.293,29.707 L0.293,1.707 Z"/>
                                <path d="M28.293,0.293 L29.707,1.707 L1.707,29.707 L0.293,28.293 L28.293,0.293 Z"/>
                            </svg>
                        </div>
                        <div className="modal__box-ct d-flex align-items-start">
                            <div className="modal__box-left">
                                <img src={require("../assets/img/t-m-2.jpg")}/>
                                <a href="#" target="_blank" className="team__soc">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="60" height="60" fill="white"/>
                                        <path d="M13.7607 16.1097C13.7607 14.8134 14.846 13.7612 16.1837 13.7612H44.1222C45.4605 13.7612 46.5452 14.8134 46.5452 16.1097V44.1976C46.5452 45.4942 45.4605 46.5456 44.1222 46.5456H16.1837C14.8461 46.5456 13.7607 45.4944 13.7607 44.198V16.1093V16.1097Z" fill="#006699"/>
                                        <path d="M23.7234 41.1976V26.438H18.8175V41.1976H23.7239H23.7234ZM21.2715 24.4232C22.9819 24.4232 24.0467 23.2898 24.0467 21.8734C24.0147 20.4247 22.9819 19.323 21.304 19.323C19.6249 19.323 18.5283 20.4247 18.5283 21.8733C18.5283 23.2897 19.5928 24.423 21.2393 24.423H21.2711L21.2715 24.4232ZM26.4388 41.1976H31.3443V32.9561C31.3443 32.5155 31.3764 32.0738 31.506 31.7592C31.8604 30.8775 32.6676 29.9647 34.0232 29.9647C35.7979 29.9647 36.5083 31.3181 36.5083 33.3025V41.1976H41.4136V32.7349C41.4136 28.2016 38.9937 26.092 35.7661 26.092C33.1199 26.092 31.9578 27.571 31.3121 28.5783H31.3447V26.4385H26.4391C26.5031 27.8231 26.4387 41.1981 26.4387 41.1981L26.4388 41.1976Z" fill="white"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="modal__box-right">
                                <div className="modal__team-header">
                                    <div className="modal__team-title">
                                        Максим Тедевосян
                                    </div>
                                    <div className="modal__team-descr">
                                        Сооснователь RAMT
                                    </div>
                                </div>
                                <div className="modal__box-scroll">
                                    <CustomScroll heightRelativeToParent="100%" allowOuterScroll="true">
                                        <div className="modal__box-subtitle">
                                            Более 20&nbsp;лет создает, развивает цифровые продукты и&nbsp;обладает опытом построения эффективных стратегических партнерств.
                                        </div>
                                        <div className="modal__box-txt">
                                            <p>
                                                Более 5&nbsp;лет возглавлял один из&nbsp;крупнейших медиахолдингов Rambler&amp;Co. Максим курировал работу всех медиаактивов, сервисов группы и&nbsp;коммерческого блока компании и&nbsp;обеспечил их&nbsp;устойчивое развитие. Под его управлением с&nbsp;2014 года выручка компании увеличилась более чем в&nbsp;3&nbsp;раза, а&nbsp;Rambler Group вышла в&nbsp;лидеры российского интернет-медиабизнеса. Максим также вошел в&nbsp;состав членов правления Rambler Group и&nbsp;принимал участие в&nbsp;сделке по&nbsp;вхождению Сбера в&nbsp;состав акционеров группы.
                                            </p>
                                            <p>
                                                Ранее построил успешную карьеру в&nbsp;рекламном бизнесе&nbsp;&mdash; сначала в&nbsp;качестве директора по&nbsp;работе с&nbsp;ключевыми партнерами и&nbsp;развитию бизнеса GroupM, позже в&nbsp;роли генерального директора одной из&nbsp;крупнейших российских рекламных холдингов&nbsp;&mdash; Progression Group. В&nbsp;этот период группа Progression удвоила свой оборот, а&nbsp;Progression вошло в&nbsp;Топ-5 digital-агентств России (рейтинг Adindex).
                                            </p>
                                            <p>
                                                Выпускник факультета политологии МГИМО (У) МИД РФ. Входит в&nbsp;рейтинг &laquo;Топ-1000 российских менеджеров&raquo; (совместный рейтинг Ассоциации менеджеров и&nbsp;газеты &laquo;Коммерсант&raquo;).
                                            </p>
                                        </div>
                                    </CustomScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div data-show={this.state.modal__team_2}  className="modal__custom modal__custom-team">
                    <div className="modal__box">
                        <div className="modal__close d-flex align-items-center justify-content-center" onClick={this.modalClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                <path d="M0.293,1.707 L1.707,0.293 L29.707,28.293 L28.293,29.707 L0.293,1.707 Z"/>
                                <path d="M28.293,0.293 L29.707,1.707 L1.707,29.707 L0.293,28.293 L28.293,0.293 Z"/>
                            </svg>
                        </div>
                        <div className="modal__box-ct d-flex align-items-start">
                            <div className="modal__box-left">
                                <img src={require("../assets/img/t-m-1.jpg")}/>
                                <a href="#" target="_blank" className="team__soc">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="60" height="60" fill="white"/>
                                        <path d="M13.7607 16.1097C13.7607 14.8134 14.846 13.7612 16.1837 13.7612H44.1222C45.4605 13.7612 46.5452 14.8134 46.5452 16.1097V44.1976C46.5452 45.4942 45.4605 46.5456 44.1222 46.5456H16.1837C14.8461 46.5456 13.7607 45.4944 13.7607 44.198V16.1093V16.1097Z" fill="#006699"/>
                                        <path d="M23.7234 41.1976V26.438H18.8175V41.1976H23.7239H23.7234ZM21.2715 24.4232C22.9819 24.4232 24.0467 23.2898 24.0467 21.8734C24.0147 20.4247 22.9819 19.323 21.304 19.323C19.6249 19.323 18.5283 20.4247 18.5283 21.8733C18.5283 23.2897 19.5928 24.423 21.2393 24.423H21.2711L21.2715 24.4232ZM26.4388 41.1976H31.3443V32.9561C31.3443 32.5155 31.3764 32.0738 31.506 31.7592C31.8604 30.8775 32.6676 29.9647 34.0232 29.9647C35.7979 29.9647 36.5083 31.3181 36.5083 33.3025V41.1976H41.4136V32.7349C41.4136 28.2016 38.9937 26.092 35.7661 26.092C33.1199 26.092 31.9578 27.571 31.3121 28.5783H31.3447V26.4385H26.4391C26.5031 27.8231 26.4387 41.1981 26.4387 41.1981L26.4388 41.1976Z" fill="white"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="modal__box-right">
                                <div className="modal__team-header">
                                    <div className="modal__team-title">
                                        Рафаэль Абрамян
                                    </div>
                                    <div className="modal__team-descr">
                                        Сооснователь и&nbsp;управляющий партнер RAMT
                                    </div>
                                </div>
                                <div className="modal__box-scroll">
                                    <CustomScroll heightRelativeToParent="100%" allowOuterScroll="true">
                                        <div className="modal__box-subtitle">
                                            Начинал карьеру старшим консультантом в&nbsp;консалтинговой компании Pricewaterhouse Coopers. Работал в&nbsp;департаменте &laquo;Стратегии и&nbsp;M&amp;A&raquo; компании CMM, управляющей медийными активами АФК &laquo;Система&raquo;.
                                        </div>
                                        <div className="modal__box-txt">
                                            <p>
                                                Более 6&nbsp;лет проработал инвестиционным директором в&nbsp;фонде А.&nbsp;Мамута группы A&amp;NN, где курировал деятельность технологических и&nbsp;интернет-активов группы. <br/>
                                                Занимался подготовкой громкой сделки&nbsp;&mdash; слиянии холдингов СУП Медиа и&nbsp;Рамблер-Афиша, в&nbsp;результате которой появилась медиа группа Rambler&amp;Co. Рафаэль был назначен CEO Rambler Group и&nbsp;введен в&nbsp;состав Совета директоров группы.
                                            </p>
                                            <p>
                                                В&nbsp;течение 5&nbsp;лет возглавлял Rambler Group, один из&nbsp;крупнейших медиахолдингов в&nbsp;России. Структура возникла в&nbsp;результате слияния Rambler&amp;Co, сети кинотеатров &laquo;Синема Парк&raquo;, &laquo;Формулы кино&raquo; и&nbsp;издательской группы &laquo;Азбука-Аттикус&raquo;. Основной задачей Рафаэля было развитие новой группы и&nbsp;повышение эффективности ее&nbsp;процессов за&nbsp;счет координации подразделений. Под его руководством Rambler Group провела более десятка M&amp;A сделок, а&nbsp;топовые проекты группы заняли лидерские позиции в&nbsp;своих сегментах. Из&nbsp;числа самых известных&nbsp;&mdash; сделка по&nbsp;поглощению кинотеатра Okko, (указать еще пару).
                                            </p>
                                            <p>
                                                Окончил экономический факультет МГУ, имеет степень магистра Финансовой академии при Правительстве РФ&nbsp;и&nbsp;степень Master of&nbsp;Science in&nbsp;Finance бизнес-школы HEC Paris. Входит в&nbsp;рейтинг &laquo;Топ-1000 российских менеджеров&raquo; (совместный рейтинг Ассоциации менеджеров и&nbsp;газеты &laquo;Коммерсант&raquo;).
                                            </p>
                                        </div>
                                    </CustomScroll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="apm-widget"></div>
            </div>

        )
    }
}


import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactWOW from 'react-wow';
import Slider from 'react-slick';
import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import { gsap } from 'gsap';
import ReactFullpage from '@fullpage/react-fullpage';
import { Swipe, Position } from 'react-swipe-component';
import disableScroll from 'disable-scroll';
import Footer from './Footer';
import Header from './Header';
/*import GoogleMapReact from 'google-map-react';*/
import { YMaps, Map, ObjectManager, Placemark } from "@pbe/react-yandex-maps";
import Modals from './Modals';

let sl_set = {},
  sl2_set = {},
  sl3_set = {},
  sl4_set = {},
  sl5_set = {},
  sl6_set = {},
  sl7_set = {},
  sl8_set = {};

let map = null;

let anchors = [
  'promo',
  'about',
  'why',
  'activities',
  /*'quote',*/
  'principles',
  'industries',
  'challenges',
  'model',
  'team-1',
  'team-2',
  /*'news',*/
  'contacts',
  'footer',
];
if (window.matchMedia('(max-width: 1023px)').matches) {
  anchors = [
    'promo',
    'about',
    'why',
    'activities',
    'target',
    /*'quote',*/
    'principles',
    'industries',
    'challenges',
    'model',
    'team-1',
    'team-2',
    /*'news',*/
    'contacts',
    'footer',
  ];
}

let animAbout1, animAbout2, animAbout3;
let animMission,
  animChallenges1,
  animChallenges2,
  animAct1,
  animAct2,
  animMbAbout1,
  animMbAbout2,
  animMbAbout3;

const mapState = {
  center: [59.953413, 30.342],
  zoom: 15.4,
  behaviors: ["default", "scrollZoom"]
};



const MapMarker = () => {
  return(
      <div className="map__marker">
        <div className="map__marker-point" />
        <div className="map__marker-balloon">
          <div className="map__marker-balloon-ct">
            <div className="map__marker-balloon-decor"></div>
            <div className="map__marker-address">
              123112, Moscow <br />
              Presnenskaya emb., 6b2, <br />
              Empire Tower, entrance 2 <br/>
              Entrance from Afimoll
            </div>
            <div className="map__marker-hint">Entrance from Afimoll</div>
          </div>
          <a
              href="tel:+74959876524"
              className="map__btn d-flex align-items-center justify-content-between">
            <i className="icon-phone">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.32 38">
                <path d="M27.69,38a9.8,9.8,0,0,1-2.94-.47A35.57,35.57,0,0,1,16,33.68a37.05,37.05,0,0,1-5.3-4.19,38.8,38.8,0,0,1-6.71-8.2,32.91,32.91,0,0,1-2.71-6A23.91,23.91,0,0,1,.17,11.67,10.37,10.37,0,0,1,.77,5.89a23.23,23.23,0,0,1,1-2.16A6.11,6.11,0,0,1,6.11.35L7.27.13,7.84,0a1,1,0,0,1,.43,0h.21a2,2,0,0,1,1.79,1l.4.67c.39.64.77,1.28,1.14,1.94s.68,1.24,1,1.86l.7,1.31c.08.15.17.28.27.42L14,7.6c.19.31.36.59.51.88a2.3,2.3,0,0,1-.33,2.75c-.5.53-1,1-1.55,1.49l-.52.48-.66.61c-.36.33-.72.66-1.07,1-.13.13-.11.17-.06.27.4.77.8,1.55,1.23,2.3a24.52,24.52,0,0,0,4.29,5.52,24.74,24.74,0,0,0,4.47,3.37c.68.41,1.38.79,2.07,1.17a.45.45,0,0,0,.21.07c.08,0,.2-.13.24-.17l.84-1,1-1.17c.47-.51,1-1.11,1.6-1.67A2.25,2.25,0,0,1,29,23.21l.49.27,1.32.78,3.12,1.83,1.61,1,.75.46a2.14,2.14,0,0,1,1.05,2,9.06,9.06,0,0,1-1.26,4.54,6.34,6.34,0,0,1-2.35,2.08,14.65,14.65,0,0,1-4.08,1.61,8.73,8.73,0,0,1-1.94.22M8,2l-.33.06-1.13.22a4.15,4.15,0,0,0-3,2.34,21.41,21.41,0,0,0-.9,2,8.28,8.28,0,0,0-.49,4.68,22.17,22.17,0,0,0,1,3.26,31.24,31.24,0,0,0,2.54,5.69A36.85,36.85,0,0,0,12,28a35.09,35.09,0,0,0,5,4,33.88,33.88,0,0,0,8.31,3.62,7.29,7.29,0,0,0,3.84.21,12.67,12.67,0,0,0,3.53-1.39A4.47,4.47,0,0,0,34.36,33a7.16,7.16,0,0,0,1-3.58c0-.16,0-.16-.1-.22l-.76-.47-1.58-1L29.78,26l-1.32-.77L28.08,25a.58.58,0,0,0-.24-.07s-.06,0-.13.07c-.53.51-1.05,1.08-1.5,1.57l-1,1.14-.86,1a2.28,2.28,0,0,1-1.73.83,2.42,2.42,0,0,1-1.17-.32C20.72,28.8,20,28.4,19.31,28a26.79,26.79,0,0,1-4.83-3.64,26.45,26.45,0,0,1-4.64-6C9.39,17.59,9,16.79,8.56,16A2.15,2.15,0,0,1,9,13.39c.37-.37.75-.72,1.14-1.07l.64-.59.53-.49c.51-.47,1-.92,1.46-1.4.06-.06.15-.15,0-.45s-.27-.48-.42-.72l-.2-.3a6.79,6.79,0,0,1-.37-.59L11,6.47c-.32-.61-.65-1.22-1-1.83S9.33,3.38,9,2.76l-.41-.68L8.36,2H8" />
                <path d="M23.35,19.91a1,1,0,0,1-.71-.3l-5-5.09a1,1,0,0,1,0-1.4l5.08-5.17L20.49,6.85A1,1,0,0,1,20.77,5L32.82,3a1,1,0,0,1,.86.28,1,1,0,0,1,.28.86l-1.9,12.26a1,1,0,0,1-.84.84,1,1,0,0,1-1-.56l-1.06-2.23-5.05,5.14a1,1,0,0,1-.71.3m-3.6-6.09,3.6,3.67L28.69,12a1,1,0,0,1,1.62.27l.28.59,1.19-7.69L24.19,6.44l.65.32a1,1,0,0,1,.27,1.6Z" />
              </svg>
            </i>
            <span>+7 495 9876542</span>
          </a>
        </div>
      </div>
  )
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: true,
      ch_cur: 0,
      about_anim: false,
      screen: 0,
      nav_sc: 1,
      ab_slide: 1,
      schema_slide: 1,
      nav1: null,
      nav2: null,
      prin: 0,
    };

    this.showSbscrModal = this.showSbscrModal.bind(this);

    this.goToAnchor = this.goToAnchor.bind(this);
  }

  componentDidMount() {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }

    document.body.classList.remove('page-not-front');

    this.setState({
      nav1: this.slider4,
      nav2: this.slider5,
    });

    window.scrollTo(0, 0);

    /*if(window.location.hash){
            if(window.location.hash === '#news'){
                this.goToAnchor('news');
            }

            if(window.location.hash === '#about'){
                this.goToAnchor('about');
            }

            if(window.location.hash === '#challenges'){
                this.goToAnchor('challenges');
            }

            if(window.location.hash === '#mission'){
                this.goToAnchor('mission');
            }

            if(window.location.hash === '#activities'){
                this.goToAnchor('activities');
            }

            window.history.pushState("", document.title, window.location.pathname + window.location.search);
        }*/

    if (window.matchMedia('(max-width: 1023px)').matches) {
      /*Promo animations*/

      gsap.from('#pr-span-1', {
        x: -100,
        opacity: 0,
        duration: 0.6,
        delay: 0.15,
      });

      gsap
        .timeline()
        .from('#pr-span-2', {
          x: -100,
          opacity: 0,
          duration: 0.3,
          delay: 0.75,
        })
        .from('#pr-span-decor-2', {
          width: 0,
          duration: 0.3,
          delay: 0.15,
        });

      gsap.from('#pr-span-3', {
        x: -100,
        opacity: 0,
        duration: 0.3,
        delay: 0.85,
      });

      gsap.from('#pr-span-4', {
        x: -100,
        opacity: 0,
        duration: 0.3,
        delay: 0.95,
      });

      gsap.from('.sc__promo-decor', {
        scale: 0,
        duration: 0.5,
        delay: 0.75,
      });

      gsap
        .timeline()
        .to('#subtitle-line-1', {
          y: '5.3rem',
          duration: 0.8,
          delay: 1.25,
        })
        .to('#subtitle-line-1', {
          x: '-200%',
          opacity: 0,
          duration: 0.3,
          delay: 0.1,
        });

      gsap.from('#subtitle-1', {
        opacity: 0,
        duration: 0.3,
        delay: 2.1,
      });

      gsap
        .timeline()
        .to('#subtitle-line-2', {
          y: '7.8rem',
          duration: 0.8,
          delay: 1.25,
        })
        .to('#subtitle-line-2', {
          x: '-200%',
          opacity: 0,
          duration: 0.3,
          delay: 0.2,
        });

      gsap.from('#subtitle-2', {
        opacity: 0,
        duration: 0.3,
        delay: 2.2,
      });

      gsap
        .timeline()
        .to('#subtitle-line-3', {
          y: '10.2rem',
          duration: 0.8,
          delay: 1.25,
        })
        .to('#subtitle-line-3', {
          x: '-200%',
          opacity: 0,
          duration: 0.3,
          delay: 0.3,
        })
        .from('#scroll-down-promo', {
          y: '-10%',
          opacity: 0,
          duration: 0.3,
          delay: 0.15,
        });

      gsap.from('#subtitle-3', {
        opacity: 0,
        duration: 0.3,
        delay: 2.3,
      });

      /*End promo animations*/

      animMbAbout1 = gsap
        .timeline()
        .from('.ab__it--1 .ab__it-title', {
          x: '-60%',
          opacity: 0,
          duration: 0.2,
          delay: 0.1,
        })
        .from('.ab__it--1 .ab__it-line', {
          width: 0,
          duration: 0.2,
          delay: 0.15,
        })
        .from('.ab__it--1 .ab__it-descr', {
          x: '-60%',
          opacity: 0,
          duration: 0.2,
          delay: 0.2,
        });

      animMbAbout2 = gsap
        .timeline()
        .from('.ab__it--2 .ab__it-title', {
          x: '-60%',
          opacity: 0,
          duration: 0.2,
          delay: 0.1,
        })
        .from('.ab__it--2 .ab__it-line', {
          width: 0,
          duration: 0.2,
          delay: 0.15,
        })
        .from('.ab__it--2 .ab__it-descr', {
          x: '-60%',
          opacity: 0,
          duration: 0.2,
          delay: 0.2,
        });

      animMbAbout3 = gsap
        .timeline()
        .from('.ab__it--3 .ab__it-title', {
          x: '-60%',
          opacity: 0,
          duration: 0.2,
          delay: 0.1,
        })
        .from('.ab__it--3 .ab__it-line', {
          width: 0,
          duration: 0.2,
          delay: 0.15,
        })
        .from('.ab__it--3 .ab__it-descr', {
          x: '-60%',
          opacity: 0,
          duration: 0.2,
          delay: 0.2,
        });

      animMbAbout1.pause();
      animMbAbout2.pause();
      animMbAbout3.pause();
    } else {
      /*Promo animations*/

      gsap.from('#pr-span-1', {
        x: -100,
        opacity: 0,
        duration: 0.6,
        delay: 0.15,
      });

      gsap.from('#pr-span-2', {
        x: -100,
        opacity: 0,
        duration: 0.3,
        delay: 0.75,
      });

      gsap.from('#pr-span-3', {
        x: -100,
        opacity: 0,
        duration: 0.3,
        delay: 0.85,
      });

      gsap.from('#pr-span-4', {
        x: -100,
        opacity: 0,
        duration: 0.3,
        delay: 0.95,
      });

      gsap.from('.sc__promo-decor', {
        scale: 0,
        duration: 0.5,
        delay: 0.75,
      });

      gsap
        .timeline()
        .to('#subtitle-line-1', {
          y: '7.5rem',
          duration: 0.8,
          delay: 1.25,
        })
        .to('#subtitle-line-1', {
          x: '100%',
          opacity: 0,
          duration: 0.3,
          delay: 0.1,
        });

      gsap.from('#subtitle-1', {
        opacity: 0,
        duration: 0.3,
        delay: 2.1,
      });

      gsap
        .timeline()
        .to('#subtitle-line-2', {
          y: '9.95rem',
          duration: 0.8,
          delay: 1.25,
        })
        .to('#subtitle-line-2', {
          x: '100%',
          opacity: 0,
          duration: 0.3,
          delay: 0.2,
        });

      gsap.from('#subtitle-2', {
        opacity: 0,
        duration: 0.3,
        delay: 2.2,
      });

      gsap
        .timeline()
        .to('#subtitle-line-3', {
          y: '12.3rem',
          duration: 0.8,
          delay: 1.25,
        })
        .to('#subtitle-line-3', {
          x: '100%',
          opacity: 0,
          duration: 0.3,
          delay: 0.3,
        })
        .from('#scroll-down-promo', {
          y: '-10%',
          opacity: 0,
          duration: 0.3,
          delay: 0.15,
        });

      gsap.from('#subtitle-3', {
        opacity: 0,
        duration: 0.3,
        delay: 2.3,
      });

      /*End promo animations*/

      animAbout1 = gsap.timeline().from(".ab__it--1 .ab__it-title", {
        x: '-60%',
        opacity: 0,
        duration: 0.4,
        delay: 0.15
      }).from(".ab__it--2 .ab__it-title", {
        x: '-60%',
        opacity: 0,
        duration: 0.4,
        delay: 0.85
      }).from(".ab__it--3 .ab__it-title", {
        x: '-60%',
        opacity: 0,
        duration: 0.4,
        delay: 1.45
      });

      animAbout1.pause();


      animAbout2 = gsap.timeline().from(".ab__it--1 .ab__it-line", {
        width: 0,
        duration: 0.4,
        delay: 0.3
      }).from(".ab__it--2 .ab__it-line", {
        width: 0,
        duration: 0.4,
        delay: 1
      }).from(".ab__it--3 .ab__it-line", {
        width: 0,
        duration: 0.4,
        delay: 1.6
      });

      animAbout2.pause();

      animAbout3 = gsap.timeline().from(".ab__it--1 .ab__it-descr", {
        x: '-60%',
        opacity: 0,
        duration: 0.4,
        delay: 0.45
      }).to(".ab__it--1", {
        y: '4rem',
        duration: 0.6,
        delay: 0.2
      }).from(".ab__it--2 .ab__it-descr", {
        x: '-60%',
        opacity: 0,
        duration: 0.4,
        delay: 0.4
      }).to(".ab__it--2", {
        y: '2rem',
        duration: 0.6,
        delay: 0.2
      }).from(".ab__it--3 .ab__it-descr", {
        x: '-60%',
        opacity: 0,
        duration: 0.4,
        delay: 0.5
      }).from(".ab__arrow-box", {
        x: '-100%',
        y: '100%',
        duration: 0.6,
        delay: 0
      });

      animAbout3.pause();

      /*Mission animations*/

      animMission = gsap
        .timeline()
        .from('.sc__mission .sc__header', {
          scale: 0,
          duration: 0.6,
          delay: 0.3,
        })
        .from('.sc__ct-box-wrap', {
          width: 0,
          duration: 0.7,
          delay: 0.1,
        });

      animMission.pause();

      /*End mission animations*/

      /*Activities animations*/

      animAct1 = gsap
        .timeline()
        .from('.schema__img', {
          opacity: 0,
          y: '4.6rem',
          duration: 0.6,
          delay: 0.2,
        })
        .from('.schema__img-over', {
          opacity: 0,
          duration: 0.3,
          delay: 0.1,
        })
        .from('.schema__it-pin-top', {
          scale: 0,
          duration: 0.3,
          delay: 0.1,
        })
        .from('.schema__decor-line-1', {
          top: '20rem',
          duration: 0.5,
          delay: 0,
        })
        .from('.schema__decor-line-2', {
          width: 0,
          duration: 0.5,
          delay: 0,
        })
        .from('.schema__decor-line-3', {
          height: 0,
          duration: 0.5,
          delay: 0,
        })
        .from('.schema__top', {
          opacity: 0,
          y: '5rem',
          duration: 0.3,
          delay: 0.1,
        })
        .from('.activities__it', {
          opacity: 0,
          x: '-10rem',
          duration: 0.3,
          delay: 0,
          stagger: {
            each: 0.15,
          },
        });

      animAct1.pause();

      animAct2 = gsap.timeline().from('.schema__target', {
        opacity: 0,
        x: '-10rem',
        duration: 0.6,
        delay: 0.25,
      });

      animAct2.pause();

      /*End activities animations*/

      /*Challenges animations*/

      animChallenges1 = gsap
        .timeline()
        .from('.ch__box-it', {
          scale: 0,
          duration: 0.5,
          delay: 0.1,
          stagger: {
            each: 0.15,
          },
        })
        .from('.active .ch__box-it-img-box', {
          height: 0,
          y: '3rem',
          duration: 0.5,
          delay: 0.1,
        });

      animChallenges1.pause();

      animChallenges2 = gsap.timeline().from('.ch__box-right', {
        scale: 0.5,
        opacity: 0,
        duration: 0.5,
        delay: 0.2,
      });
      animChallenges2.pause();

      /*End challenges animations*/
    }


    map = null;
    if(document.getElementById('map')){
      document.getElementById('map').innerHTML = '';
    }

    window.ymaps.ready(function () {
      map = new window.ymaps.Map('map', {
        center: [55.7485814,37.5419243],
        zoom: 15,
        type: 'yandex#map',
        behaviors: ['default', 'scrollZoom'],
        controls: ['zoomControl']
      });

      let squareLayout = window.ymaps.templateLayoutFactory.createClass('<div class="map__marker">\n' +
          '        <div class="map__marker-point" />\n' +
          '        <div class="map__marker-balloon">\n' +
          '          <div class="map__marker-balloon-ct">\n' +
          '            <div class="map__marker-balloon-decor"></div>\n' +
          '            <div class="map__marker-address">\n' +
          '              123112, Moscow <br />\n' +
          '              Presnenskaya emb., 6b2, <br />\n' +
          '              Empire Tower, entrance 2 <br/>\n' +
          '              Entrance from Afimoll\n' +
          '            </div>\n' +
          '            <div class="map__marker-hint">Entrance from Afimoll</div>\n' +
          '          </div>\n' +
          '          <a\n' +
          '              href="tel:+74959876524"\n' +
          '              class="map__btn d-flex align-items-center justify-content-between">\n' +
          '            <i class="icon-phone">\n' +
          '              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.32 38">\n' +
          '                <path d="M27.69,38a9.8,9.8,0,0,1-2.94-.47A35.57,35.57,0,0,1,16,33.68a37.05,37.05,0,0,1-5.3-4.19,38.8,38.8,0,0,1-6.71-8.2,32.91,32.91,0,0,1-2.71-6A23.91,23.91,0,0,1,.17,11.67,10.37,10.37,0,0,1,.77,5.89a23.23,23.23,0,0,1,1-2.16A6.11,6.11,0,0,1,6.11.35L7.27.13,7.84,0a1,1,0,0,1,.43,0h.21a2,2,0,0,1,1.79,1l.4.67c.39.64.77,1.28,1.14,1.94s.68,1.24,1,1.86l.7,1.31c.08.15.17.28.27.42L14,7.6c.19.31.36.59.51.88a2.3,2.3,0,0,1-.33,2.75c-.5.53-1,1-1.55,1.49l-.52.48-.66.61c-.36.33-.72.66-1.07,1-.13.13-.11.17-.06.27.4.77.8,1.55,1.23,2.3a24.52,24.52,0,0,0,4.29,5.52,24.74,24.74,0,0,0,4.47,3.37c.68.41,1.38.79,2.07,1.17a.45.45,0,0,0,.21.07c.08,0,.2-.13.24-.17l.84-1,1-1.17c.47-.51,1-1.11,1.6-1.67A2.25,2.25,0,0,1,29,23.21l.49.27,1.32.78,3.12,1.83,1.61,1,.75.46a2.14,2.14,0,0,1,1.05,2,9.06,9.06,0,0,1-1.26,4.54,6.34,6.34,0,0,1-2.35,2.08,14.65,14.65,0,0,1-4.08,1.61,8.73,8.73,0,0,1-1.94.22M8,2l-.33.06-1.13.22a4.15,4.15,0,0,0-3,2.34,21.41,21.41,0,0,0-.9,2,8.28,8.28,0,0,0-.49,4.68,22.17,22.17,0,0,0,1,3.26,31.24,31.24,0,0,0,2.54,5.69A36.85,36.85,0,0,0,12,28a35.09,35.09,0,0,0,5,4,33.88,33.88,0,0,0,8.31,3.62,7.29,7.29,0,0,0,3.84.21,12.67,12.67,0,0,0,3.53-1.39A4.47,4.47,0,0,0,34.36,33a7.16,7.16,0,0,0,1-3.58c0-.16,0-.16-.1-.22l-.76-.47-1.58-1L29.78,26l-1.32-.77L28.08,25a.58.58,0,0,0-.24-.07s-.06,0-.13.07c-.53.51-1.05,1.08-1.5,1.57l-1,1.14-.86,1a2.28,2.28,0,0,1-1.73.83,2.42,2.42,0,0,1-1.17-.32C20.72,28.8,20,28.4,19.31,28a26.79,26.79,0,0,1-4.83-3.64,26.45,26.45,0,0,1-4.64-6C9.39,17.59,9,16.79,8.56,16A2.15,2.15,0,0,1,9,13.39c.37-.37.75-.72,1.14-1.07l.64-.59.53-.49c.51-.47,1-.92,1.46-1.4.06-.06.15-.15,0-.45s-.27-.48-.42-.72l-.2-.3a6.79,6.79,0,0,1-.37-.59L11,6.47c-.32-.61-.65-1.22-1-1.83S9.33,3.38,9,2.76l-.41-.68L8.36,2H8" />\n' +
          '                <path d="M23.35,19.91a1,1,0,0,1-.71-.3l-5-5.09a1,1,0,0,1,0-1.4l5.08-5.17L20.49,6.85A1,1,0,0,1,20.77,5L32.82,3a1,1,0,0,1,.86.28,1,1,0,0,1,.28.86l-1.9,12.26a1,1,0,0,1-.84.84,1,1,0,0,1-1-.56l-1.06-2.23-5.05,5.14a1,1,0,0,1-.71.3m-3.6-6.09,3.6,3.67L28.69,12a1,1,0,0,1,1.62.27l.28.59,1.19-7.69L24.19,6.44l.65.32a1,1,0,0,1,.27,1.6Z" />\n' +
          '              </svg>\n' +
          '            </i>\n' +
          '            <span>+7 495 9876542</span>\n' +
          '          </a>\n' +
          '        </div>\n' +
          '      </div>');

      let squarePlacemark = new window.ymaps.Placemark(
          [55.7485997, 37.5363214], {
            hintContent: ''
          }, {
            iconLayout: squareLayout,
            // Описываем фигуру активной области "Прямоугольник".
            iconShape: {
              type: 'Rectangle',
              // Прямоугольник описывается в виде двух точек - верхней левой и нижней правой.
              coordinates: [
                [-25, -25], [25, 25]
              ]
            }
          }
      );
      map.geoObjects.add(squarePlacemark);
    });
  }

  componentWillMount() {
    sl_set = {
      infinite: false,
      dots: false,
      arrows: false,
      swipeToSlide: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      speed: 800,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
          },
        },
      ],
      beforeChange: (oldIndex, newIndex) => {
        this.setState({
          ch_cur: newIndex,
        });

        document.querySelectorAll('.ch__box-it-img-box')[0].removeAttribute('style');
      },
    };

    sl2_set = {
      infinite: true,
      dots: true,
      arrows: false,
      swipeToSlide: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 800,
    };

    sl3_set = {
      infinite: true,
      dots: false,
      arrows: false,
      swipeToSlide: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 800,
    };

    sl4_set = {
      infinite: true,
      dots: false,
      arrows: false,
      swipeToSlide: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 800,
      adaptiveHeight: true,
      fade: true,
    };

    sl5_set = {
      infinite: true,
      dots: false,
      arrows: false,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: 0,
      variableWidth: true,
    };

    sl6_set = {
      infinite: false,
      dots: false,
      arrows: false,
      swipeToSlide: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      speed: 800,
    };

    sl7_set = {
      infinite: true,
      dots: false,
      arrows: false,
      swipeToSlide: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      speed: 800,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
          },
        },
      ],
    };

    sl8_set = {
      infinite: true,
      dots: false,
      arrows: false,
      swipeToSlide: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 800,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            adaptiveHeight: true,
          },
        },
      ],
      beforeChange: (current, next) => {
        this.setState({ prin: next });
      },
    };
  }

  goToAnchor = (anchor) => {
    this.setState(
      {
        nav_sc: anchor,
      },
      () => {
        let el = document.getElementById('goToBtn');
        el.click();
      },
    );
  };

  showSbscrModal = () => {
    this.modals.subscribeShow();
  };

  createMapOptions = (maps) => {
    return {
      panControl: false,
      scrollwheel: false,
      styles: [
        {
          stylers: [
            {
              featureType: 'water',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#d3d3d3',
                },
              ],
            },
            {
              featureType: 'transit',
              stylers: [
                {
                  color: '#808080',
                },
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#b3b3b3',
                },
              ],
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#ffffff',
                },
              ],
            },
            {
              featureType: 'road.local',
              elementType: 'geometry.fill',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#ffffff',
                },
                {
                  weight: 1.8,
                },
              ],
            },
            {
              featureType: 'road.local',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#d7d7d7',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'geometry.fill',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#ebebeb',
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#a7a7a7',
                },
              ],
            },
            {
              featureType: 'road.arterial',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#ffffff',
                },
              ],
            },
            {
              featureType: 'road.arterial',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#ffffff',
                },
              ],
            },
            {
              featureType: 'landscape',
              elementType: 'geometry.fill',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#efefef',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#696969',
                },
              ],
            },
            {
              featureType: 'administrative',
              elementType: 'labels.text.fill',
              stylers: [
                {
                  visibility: 'on',
                },
                {
                  color: '#737373',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {
              featureType: 'road.arterial',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#d6d6d6',
                },
              ],
            },
            {
              featureType: 'road',
              elementType: 'labels.icon',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
            {},
            {
              featureType: 'poi',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#dadada',
                },
              ],
            },
          ],
        },
      ],
    };
  };

  renderNewsBox = () => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      return (
        <>
          <div className="news__sl-box">
            <Slider ref={(slider2) => (this.slider2 = slider2)} {...sl2_set}>
              <div className="news__it-slide news__it-slide--1">
                <div className="news__it-slide-ct">
                  <div className="news__it-date">2 Марта, 2023</div>
                  <Link
                    to={{
                      pathname: '/news/byvshie-top-menedzhery-rambler-zapustili-tsifrovoi-startap',
                      state: { prevPath: window.location.pathname },
                    }}
                    className="d-flex align-items-start justify-content-between">
                    <div className="news__it-title">
                      Бывшие топ-менеджеры Rambler запустили цифровой стартап
                    </div>
                    <div className="news__it-arrow-sm">
                      <svg viewBox="0 0 80 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 2.99463L78.8591 2.99463" stroke="#FF1EFF" stroke-width="2" />
                        <path d="M80 2.99465L76.0071 0L76.0071 5.9893L80 2.99465Z" fill="#FF1EFF" />
                      </svg>
                    </div>
                  </Link>
                  <div className="news__it-descr">
                    Рафаэль Абрамян и&nbsp;Максим Тадевосян будут развивать цифровые проекты для компаний реального сектора
                  </div>
                </div>

                <div className="news__it-bottom d-flex align-items-center justify-content-between">
                  <div className="news__it-likes d-flex align-items-start">
                    <i className="icon__likes" />
                    <span className="news__it-likes-info">420</span>
                  </div>
                  <div className="news__it-comments">128 comments</div>
                  <a href="#" className="news__it-social">
                    f
                  </a>
                </div>
              </div>
              <div className="news__it-slide news__it-slide--2">
                <div
                  className="news__it-slide-ct"
                  style={{ backgroundImage: 'url(' + require('../assets/img/n-img-1.jpg') + ')' }}>
                  <div className="news__it-date">2 Марта, 2023</div>
                  <Link
                    to={{
                      pathname: '/news/forbes-sobiraet-sovety',
                      state: { prevPath: window.location.pathname },
                    }}
                    className="d-flex align-items-start justify-content-between">
                    <div className="news__it-title">Forbes собирает советы</div>
                    <div className="news__it-arrow-sm">
                      <svg viewBox="0 0 80 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 2.99463L78.8591 2.99463" stroke="#FF1EFF" stroke-width="2" />
                        <path d="M80 2.99465L76.0071 0L76.0071 5.9893L80 2.99465Z" fill="#FF1EFF" />
                      </svg>
                    </div>
                  </Link>
                  <div className="news__it-swipe-info">&lt;&lt; swipe for more &gt;&gt;</div>
                </div>
                <div className="news__it-bottom d-flex align-items-center justify-content-between">
                  <div className="news__it-likes d-flex align-items-start">
                    <i className="icon__likes" />
                    <span className="news__it-likes-info">180</span>
                  </div>
                  <div className="news__it-comments">15 comments</div>
                  <a href="#" className="news__it-social">
                    f
                  </a>
                </div>
              </div>
            </Slider>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="news__net">
            <div className="news__it news__it--1 news__it--full">
              <Link
                to={{
                  pathname: '/news/byvshie-top-menedzhery-rambler-zapustili-tsifrovoi-startap',
                  state: { prevPath: window.location.pathname },
                }}
                className="news__it-bl">
                <div className="news__it-title">
                  Бывшие топ-менеджеры Rambler запустили цифровой стартап
                </div>
                <div className="news__it-descr">
                  Рафаэль Абрамян и&nbsp;Максим Тадевосян будут развивать цифровые проекты для компаний реального сектора
                </div>
                <div className="news__it-info d-flex align-items-center">
                  <div className="news__it-likes d-flex align-items-start">
                    <i className="icon__likes" />
                    <span className="news__it-likes-info">420</span>
                  </div>
                  <div className="news__it-comments">128 comments</div>
                </div>
                <a href="#" className="news__it-social">
                  f
                </a>
                <div className="news__it-arrow" />
              </Link>
              <span className="news__it-decor" />
            </div>
            <div className="news__it news__it--2">
              <div
                className="news__it-bl"
                style={{ backgroundImage: 'url(' + require('../assets/img/n-img-1.jpg') + ')' }}
              />
            </div>
            <div className="news__it news__it--3">
              <div
                className="news__it-bl"
                style={{
                  backgroundImage: 'url(' + require('../assets/img/n-img-2.jpg') + ')',
                }}></div>
            </div>
            <div className="news__it news__it--1 news__it--rt-ct">
              <Link
                to={{ pathname: '/post', state: { prevPath: window.location.pathname } }}
                className="news__it-bl">
                <div className="news__it-date">28 April, 2022</div>
                <div className="news__it-title">Forbes собирает советы</div>
                <div className="news__it-descr">
                  Журнал нанял внешних консультантов для повышения стоимости бизнеса
                </div>
                <div className="news__it-arrow" />
              </Link>
              {/*<span className="news__it-decor"/>*/}
            </div>
            <div className="news__it news__it--1 news__it--rt-bt">
              <a href="#" className="news__it-social">
                f
              </a>
            </div>
          </div>
          {/*<div className="load__more-wrap d-flex justify-content-center">
                        <div className="btn__custom d-flex align-items-center justify-content-center">
                            More news
                        </div>
                    </div>*/}
        </>
      );
    }
  };

  onSwipeUpListener = () => {
    if (this.state.ab_slide < 3) {
      this.setState(
        {
          ab_slide: parseInt(this.state.ab_slide) + 1,
        },
        () => {
          gsap.to('.ab__arrow', {
            x: (parseInt(this.state.ab_slide) - 1) * 13.5 + 'rem',
            y: -(parseInt(this.state.ab_slide) - 1) * 13.5 + 'rem',
            duration: 0.5,
            delay: 0.3,
          });
          if (this.state.ab_slide === 2) {
            animMbAbout2.play();
          }
          if (this.state.ab_slide === 3) {
            animMbAbout3.play();
          }
        },
      );
    } else {
      let el1 = document.getElementById('enableScroll');
      el1.click();
      this.goToAnchor(11);
    }
  };

  onSwipeUpListener2 = () => {
    if (this.state.schema_slide < 3) {
      this.setState({
        schema_slide: parseInt(this.state.schema_slide) + 1,
      });
    } else {
      let el1 = document.getElementById('enableScroll');
      el1.click();
      this.goToAnchor(9);
    }
  };

  onSwipeDownListener = () => {
    if (this.state.ab_slide > 1) {
      this.setState(
        {
          ab_slide: parseInt(this.state.ab_slide) - 1,
        },
        () => {
          let shift = 0;
          if (this.state.ab_slide === 2) {
            shift = 13.5;
          }

          gsap.to('.ab__arrow', {
            x: shift + 'rem',
            y: -1 * shift + 'rem',
            duration: 0.5,
            delay: 0.3,
          });
        },
      );
    } else {
      let el1 = document.getElementById('enableScroll');
      el1.click();
      this.goToAnchor(9);
    }
  };

  onSwipeDownListener2 = () => {
    if (this.state.schema_slide > 1) {
      this.setState({
        schema_slide: parseInt(this.state.schema_slide) - 1,
      });
    } else {
      let el1 = document.getElementById('enableScroll');
      el1.click();
      this.goToAnchor(5);
    }
  };

  renderAbBox = () => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      return (
          <Swipe detectTouch={true} onSwipedUp={this.onSwipeUpListener} onSwipedDown={this.onSwipeDownListener}>
            <div className="container">
              <div className="ab__box" data-slide={this.state.ab_slide}>
                <div
                    className={
                        'ab__it ab__it--1 fadeInUpDes animated ' + (this.state.ab_slide === 1 ? 'active' : '')
                    }>
                  <div className="ab__it-header">
                    <div className="ab__it-title">
                      Мы&nbsp;работаем по&nbsp;модели капитализации проектов Sweat Equity.
                    </div>
                    <div className="ab__it-lines">
                      <div className="ab__it-line" />
                    </div>
                  </div>
                  <div className="ab__it-descr">
                    Это <span>инвестиции</span> в&nbsp;компанию партнера экспертизой, широкими связями и&nbsp;интеллектуальным капиталом вместо финансового. Мы&nbsp;раскрываем потенциал бизнеса и&nbsp;повышаем его ценность в&nbsp;обмен на&nbsp;долю. Именно поэтому нам важно добиться высокого коммерческого результата вместе с&nbsp;собственником. Это кропотливый и&nbsp;длительный путь, ведущий к&nbsp;увеличению стоимости компании.
                  </div>
                </div>
                <div
                    className={
                        'ab__it ab__it--2 fadeInUpDes animated ' + (this.state.ab_slide === 2 ? 'active' : '')
                    }>
                  <div className="ab__it-header">
                    <div className="ab__it-title">
                      Sweat Equity имеет многолетнюю доказанную эффективность в&nbsp;США.
                    </div>
                    <div className="ab__it-lines">
                      <div className="ab__it-line" />
                      <div className="ab__it-line" />
                    </div>
                  </div>
                  <div className="ab__it-descr">
                    Особенно часто эта модель применяется в&nbsp;работе со&nbsp;стартапами в&nbsp;условиях ограниченного финансирования. Немонетарные инвестиции помогают бизнесу привлекать и&nbsp;удерживать лучших сотрудников, компенсируя их&nbsp;усилия и&nbsp;вклад в&nbsp;процесс. Стоимость доли в&nbsp;капитале растет по&nbsp;мере роста бизнеса, поэтому работа в&nbsp;рамках Sweat Equity&nbsp;&mdash; это стимул для всех участников обеспечивать стабильное развитие компании.
                  </div>
                </div>
                <div
                    className={
                        'ab__it ab__it--3 fadeInUpDes animated ' + (this.state.ab_slide === 3 ? 'active' : '')
                    }>
                  <div className="ab__it-header">
                    <div className="ab__it-title">
                      Как это работает на&nbsp;практике?
                    </div>
                    <div className="ab__it-lines">
                      <div className="ab__it-line" />
                      <div className="ab__it-line" />
                      <div className="ab__it-line" />
                    </div>
                  </div>

                  <div className="ab__it-descr">
                    Опираясь на&nbsp;конкретный запрос, мы&nbsp;анализируем состояние компании, текущие бизнес-процессы, определяем точки роста, разрабатываем стратегию и&nbsp;индивидуальный цифровой продукт. Для его запуска мы&nbsp;интегрируемся в&nbsp;бизнес партнера&nbsp;&mdash; <span>формируем эффективную команду</span> из&nbsp;экспертов RAMT и&nbsp;персонала заказчика, который обучаем самостоятельно управлять изменениями. В&nbsp;нашем партнерстве не&nbsp;может быть рисков, мы&nbsp;придерживаемся подхода&nbsp;&mdash; вознаграждение только за&nbsp;достижение результата.
                  </div>
                </div>
              </div>
              <div className="ab__arrow"/>
            </div>
          </Swipe>
      );
    } else {
      return (
        <>
          <Swipe detectTouch={true} onSwipedUp={this.onSwipeUpListener} onSwipedDown={this.onSwipeDownListener}>
            <div className="container">
              <div className="sc__title">
                Бизнес-модель
              </div>
              <div className="ab__box" data-slide={this.state.ab_slide}>
                <div className="ab__row">
                  <div className="ab__it ab__it--1">
                    <div className="ab__it-title">
                      Мы&nbsp;работаем по&nbsp;модели капитализации проектов Sweat Equity.
                    </div>
                    <div className="ab__it-lines">
                      <div className="ab__it-line" />
                    </div>
                    <div className="ab__it-descr">
                      Это <span>инвестиции</span> в&nbsp;компанию партнера экспертизой, широкими связями и&nbsp;интеллектуальным капиталом вместо финансового. Мы&nbsp;раскрываем потенциал бизнеса и&nbsp;повышаем его ценность в&nbsp;обмен на&nbsp;долю. Именно поэтому нам важно добиться высокого коммерческого результата вместе с&nbsp;собственником. Это кропотливый и&nbsp;длительный путь, ведущий к&nbsp;увеличению стоимости компании.
                    </div>
                  </div>
                  <div className="ab__it ab__it--2">
                    <div className="ab__it-title">
                      Sweat Equity имеет многолетнюю доказанную эффективность в&nbsp;США.
                    </div>
                    <div className="ab__it-lines">
                      <div className="ab__it-line" />
                      <div className="ab__it-line" />
                    </div>
                    <div className="ab__it-descr">
                      Особенно часто эта модель применяется в&nbsp;работе со&nbsp;стартапами в&nbsp;условиях ограниченного финансирования. Немонетарные инвестиции помогают бизнесу привлекать и&nbsp;удерживать лучших сотрудников, компенсируя их&nbsp;усилия и&nbsp;вклад в&nbsp;процесс. Стоимость доли в&nbsp;капитале растет по&nbsp;мере роста бизнеса, поэтому работа в&nbsp;рамках Sweat Equity&nbsp;&mdash; это стимул для всех участников обеспечивать стабильное развитие компании.
                    </div>
                  </div>
                  <div className="ab__it ab__it--3">
                    <div className="ab__it-title">
                      Как это работает на&nbsp;практике?
                    </div>
                    <div className="ab__it-lines">
                      <div className="ab__it-line" />
                      <div className="ab__it-line" />
                      <div className="ab__it-line" />
                    </div>
                    <div className="ab__it-descr">
                      Опираясь на&nbsp;конкретный запрос, мы&nbsp;анализируем состояние компании, текущие бизнес-процессы, определяем точки роста, разрабатываем стратегию и&nbsp;индивидуальный цифровой продукт. Для его запуска мы&nbsp;интегрируемся в&nbsp;бизнес партнера&nbsp;&mdash; <span>формируем эффективную команду</span> из&nbsp;экспертов RAMT и&nbsp;персонала заказчика, который обучаем самостоятельно управлять изменениями. В&nbsp;нашем партнерстве не&nbsp;может быть рисков, мы&nbsp;придерживаемся подхода&nbsp;&mdash; вознаграждение только за&nbsp;достижение результата.
                    </div>
                  </div>
                </div>
                <div className="ab__arrow-box">
                  <div className="ab__arrow" />
                </div>
              </div>
            </div>
          </Swipe>
        </>
      );
    }
  };

  renderSchema = () => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      return (
        <div className="schema__box">
          <div className="schema__box-wrap">
            <div className="schema__box-top fadeInUpDes animated active">
              <div className="schema__box-top-title">
                Принципы нашей работы
              </div>
            </div>
            <div className="schema__img">
              <img src={require('../assets/img/schema-mb.svg').default} />

              <div
                className={
                  'schema__active schema__active--1 fadeIn animated ' +
                  (this.state.schema_slide === 1 ? 'active' : '')
                }>
                <img src={require('../assets/img/sch_act-1.svg').default} />
              </div>
              <div
                className={
                  'schema__active schema__active--2 fadeIn animated ' +
                  (this.state.schema_slide === 2 ? 'active' : '')
                }>
                <img src={require('../assets/img/sch_act-2.svg').default} />
              </div>
              <div
                className={
                  'schema__active schema__active--3 fadeIn animated ' +
                  (this.state.schema_slide === 3 ? 'active' : '')
                }>
                <img src={require('../assets/img/sch_act-3.svg').default} />
              </div>

              <div
                className={
                  'schema__it-mb schema__it-mb-1 ' + (this.state.schema_slide === 1 ? 'active' : '')
                }>
                <div className="schema__it-mb-pin"></div>
              </div>

              {/*<div className={'schema__it-mb schema__it-mb-2 fadeInUpDes animated ' + ((this.state.schema_slide === 2) ? 'active' : '')}>
                                <div className="schema__it-mb-pin"></div>
                            </div>

                            <div className={'schema__it-mb schema__it-mb-3 fadeInUpDes animated ' + ((this.state.schema_slide === 3) ? 'active' : '')}>
                                <div className="schema__it-mb-pin"></div>
                            </div>*/}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="schema__box">
          <div className="schema__img">
            <img src={require('../assets/img/schema.png')} />
            <div className="schema__img-over">
              <img src={require('../assets/img/schema-over.png')} />
            </div>
          </div>
          {/*<div className="schema__it schema__it--1">
                        <div className="schema__it-title">
                            Investment activity <br/>
                            (M&A)
                        </div>
                        <div className="schema__it-pin"/>
                    </div>
                    </div>
                    <div className="schema__it schema__it--2">
                        <div className="schema__it-title">
                            Transformation <br/>
                            and creation of new capital <br/>
                            value (project base, M&A)
                        </div>
                        <div className="schema__it-pin"/>
                    </div>
                    <div className="schema__it schema__it--3">
                        <div className="schema__it-title">
                            Management <br/>
                            company <br/>
                            (JV / option)
                        </div>
                        <div className="schema__it-pin"/>
                    </div>*/}
          <div className="schema__top">
            Принципы нашей работы
          </div>
          <div className="schema__it-pin-top" />
          <div className="schema__decor">
            <div className="schema__decor-line-1" />
            <div className="schema__decor-line-2" />
            <div className="schema__decor-line-3" />
          </div>
          <div className="schema__target">
            <div className="schema__target-title">
              Глобальный <br />
              подход
            </div>
            <div className="schema__target-descr">
              Наши лучшие продуктовые решения должны быть трансграничны и&nbsp;экспортируемы.
            </div>
          </div>
        </div>
      );
    }
  };

  renderTargetSection = () => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      return (
        <>
          <div className="section sc__target fp-auto-height">
            <div className="schema__target">
              <div className="schema__target-title">Глобальный подход</div>
              <div className="schema__target-descr">
                Наши лучшие продуктовые решения должны быть трансграничны и&nbsp;экспортируемы.
              </div>
            </div>
            <div className="activities__list">
              <ReactWOW animation='fadeInRight' delay='0.1s'>
                <div className="activities__it">
                  <div className="d-flex">
                    <div className="activities__it-numb">1</div>
                  </div>
                  <div className="activities__it-descr">
                    Погружение в&nbsp;бизнес партнера. Разрабатываем стратегию на&nbsp;основе бизнес-аудита компании.
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation='fadeInRight' delay='0.15s'>
                <div className="activities__it">
                  <div className="d-flex">
                    <div className="activities__it-numb">2</div>
                  </div>
                  <div className="activities__it-descr">
                    Сочетание экспертизы RAMT и&nbsp;компетенций партнера. Создаем внутреннюю команду по&nbsp;цифровой трансформации и&nbsp;обучаем ее&nbsp;работать самостоятельно.
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation='fadeInRight' delay='0.2s'>
                <div className="activities__it">
                  <div className="d-flex">
                    <div className="activities__it-numb">3</div>
                  </div>
                  <div className="activities__it-descr">
                    Ответственность перед партнером. Заинтересованность в&nbsp;высоком финансовом результате заложена в&nbsp;саму модель взаимоотношений.
                  </div>
                </div>
              </ReactWOW>
              <ReactWOW animation='fadeInRight' delay='0.25s'>
                <div className="activities__it">
                  <div className="d-flex">
                    <div className="activities__it-numb">4</div>
                  </div>
                  <div className="activities__it-descr">
                    Реальный результат. Результат партнерства&nbsp;&mdash; это готовый продукт с&nbsp;перспективой дальнейшего коммерческого развития и&nbsp;новая капитальная стоимость бизнеса.
                  </div>
                </div>
              </ReactWOW>
            </div>
          </div>
        </>
      );
    }
  };

  renderContactsSection = () => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      return (
        <>
          <div className="section sc__contacts">
            <div className="d-flex section__scroll-box">
              <div className="scroll__down d-flex align-items-end">
                <div className="scroll__down-arrow"></div>
                <div className="scroll__down-title">Контакты</div>
              </div>
            </div>
            <div className="container">
              <div className="subscribe__form-box">
                <div className="row row__custom">
                  <div className="col col-lg-6 col-12">
                    <div className="subscribe__form-header">
                      <div className="subscribe__form-title">Контакты</div>
                      <div className="subscribe__form-subtitle">
                        Спасибо вам за&nbsp;интересы к&nbsp;RAMT. Пожалуйста, заполните поля ниже или сообщите о&nbsp;технической проблеме
                      </div>
                    </div>
                    <div className="subscribe__form">
                      <div className="row row__custom form__list">
                        <div className="col col-lg-6 col-12 form__col">
                          <div className="form__it">
                            <div className="form__field">
                              <input type="text" placeholder="Имя*" />
                            </div>
                          </div>
                          <div className="form__it">
                            <div className="form__field">
                              <input type="text" placeholder="Email*" />
                            </div>
                          </div>
                        </div>
                        <div className="col col-lg-6 col-12 form__col">
                          <div className="form__field">
                            <textarea placeholder="Сообщение*"></textarea>
                          </div>
                        </div>
                        <div className="col col-lg-6 col-12 form__it">
                          <div className="checkbox__wrap">
                            <label>
                              <input type="checkbox" />
                              <span className="checkbox__decor" />
                              <span className="checkbox__title">
                                Я&nbsp;согласен на&nbsp;обработку персональныз данных
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="col col-lg-6 col-12 form__col">
                          <div className="form__hint">Поля под * обязательные для заполнения</div>
                        </div>
                        <div className="col col-lg-6 col-12 form__it">
                          <button
                            type="submit"
                            className="form__submit d-flex align-items-center justify-content-center">
                            Отправить
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="map" className="map">
                {/*<div style={{ height: '100%', width: '100%' }}>
                  <YMaps>
                    <Map width="100%" height="100%" state={mapState}>
                      <Placemark geometry={[59.953413, 30.342]} />
                      <ObjectManager
                          objects={{
                            openBalloonOnClick: true
                          }}
                          clusters={{}}
                          options={{
                            gridSize: 32
                          }}
                          defaultFeatures={collection}
                          modules={[
                            "objectManager.addon.objectsBalloon",
                            "objectManager.addon.clustersBalloon"
                          ]}
                      />
                    </Map>
                  </YMaps>
                  <GoogleMapReact
                    options={this.createMapOptions}
                    defaultCenter={{ lat: 59.953413, lng: 30.342 }}
                    defaultZoom={15}>
                    <MapMarker lat={59.955413} lng={30.337844} />
                  </GoogleMapReact>
                </div>*/}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="section sc__contacts">
          <div className="d-flex section__scroll-box">
            <div className="scroll__down d-flex align-items-end">
              <div className="scroll__down-arrow"></div>
              <div className="scroll__down-title">Контакты</div>
            </div>
          </div>
          <div className="container">
            <div className="subscribe__form-box">
              <div className="row row__custom">
                <div className="col col-lg-6 col-12">
                  <div className="subscribe__form-header">
                    <div className="subscribe__form-title">Контакты</div>
                    <div className="subscribe__form-subtitle">
                      Спасибо вам за&nbsp;интересы к&nbsp;RAMT. Пожалуйста, заполните поля ниже или сообщите о&nbsp;технической проблеме
                    </div>
                  </div>
                  <div className="subscribe__form">
                    <div className="row row__custom form__list">
                      <div className="col col-lg-6 col-12 form__col">
                        <div className="form__it">
                          <div className="form__field">
                            <input type="text" placeholder="Имя*" />
                          </div>
                        </div>
                        <div className="form__it">
                          <div className="form__field">
                            <input type="text" placeholder="Email*" />
                          </div>
                        </div>
                        <div className="form__it">
                          <div className="form__field">
                            <input type="text" placeholder="Компания*" />
                          </div>
                        </div>
                        <div className="form__it">
                          <div className="form__field">
                            <input type="text" placeholder="Тема*" />
                          </div>
                        </div>
                      </div>
                      <div className="col col-lg-6 col-12 form__col">
                        <div className="form__field">
                          <textarea placeholder="Сообщение*"></textarea>
                        </div>
                      </div>
                      <div className="col col-lg-6 col-12 form__it">
                        <div className="checkbox__wrap">
                          <label>
                            <input type="checkbox" />
                            <span className="checkbox__decor" />
                            <span className="checkbox__title">
                              Я согласен на обработку персональныз данных
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col col-lg-6 col-12 form__col">
                        <div className="form__hint">Поля под * обязательные для заполнения</div>
                      </div>
                      <div className="col col-lg-6 col-12 form__it">
                        <button
                          type="submit"
                          className="form__submit d-flex align-items-center justify-content-center">
                          Отправить
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col col-lg-6 col-12">
                  <div id="map" className="map">

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  renderChallengesMobile = () => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      return (
        <div className="ch__mobile-box">
          <Slider
            asNavFor={this.state.nav2}
            ref={(slider4) => (this.slider4 = slider4)}
            {...sl4_set}>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-1.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-1.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  <span>92/8</span>&nbsp;&mdash; диспропорция соотношения онлайн и&nbsp;офлайн бизнеса в&nbsp;России.
                  Сегодня цифровой бизнес составляет лишь&nbsp;<span>8%</span>, но&nbsp;оказывает
                  существенное влияние на&nbsp;классические индустрии.
                </div>
              </div>
            </div>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-2.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-2.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  Лишь <span>8%</span> компаний считают, что их&nbsp;бизнес-модель устоит в&nbsp;условиях
                  растущей цифровизации (по&nbsp;данным глобального{' '}
                  <a href="#" target="_blank">
                    опроса
                  </a>{' '}
                  McKinsey)
                </div>
              </div>
            </div>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-3.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-3.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  Большинство крупных компаний, образованных еще в&nbsp;20&nbsp;веке, по&nbsp;традиционным
                  классическим моделям не&nbsp;способны к&nbsp;быстрым и&nbsp;гибким изменениям по&nbsp;своей сути.
                  Именно поэтому они все меньше соответствуют той внешней среде, в&nbsp;которой им
                  приходится существовать.
                </div>
              </div>
            </div>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-4.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-4.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  Сегодня компании во&nbsp;всех сферах осознают важность поиска новых точек роста,
                  увеличение скорости адаптации к&nbsp;внешним изменениям и&nbsp;повышение
                  конкурентоспособности как залога сохранения бизнеса. Первыми об&nbsp;этом традиционно
                  задумались наиболее гибкие и&nbsp;высоко адаптивные бизнесы&nbsp;&mdash; IT, логистика, финтех,
                  ритейл и&nbsp;e-commerce.
                </div>
              </div>
            </div>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-5.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-5.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  <span>Общие факторы</span>, способствующие перестройке бизнеса независимо от&nbsp;его
                  размера, отраслевой и&nbsp;географической принадлежности&nbsp;&mdash; это новые технологии,
                  международные политические и&nbsp;экономические процессы, климатические и&nbsp;экологические
                  изменения, смена поколений и&nbsp;их&nbsp;потребительских моделей, а&nbsp;также общемировой
                  дефицит квалифицированных кадров.
                </div>
              </div>
            </div>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-6.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-6.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  Любые изменения в&nbsp;компании должны происходить строго в&nbsp;условиях индивидуального
                  подхода. Попытки копирования лучших практик с&nbsp;рынка обычно оказываются ошибочным
                  решением. Даже в&nbsp;одной сфере крупным компаниям с&nbsp;уникальными бизнес-процессами
                  требуются <span>индивидуальные решения</span>, основанные на&nbsp;множестве факторов.
                </div>
              </div>
            </div>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-7.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-7.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  <span>Большая часть</span> попыток бизнеса трансформировать свою модель и
                  адаптироваться к&nbsp;новым реалиям заканчиваются неудачей. Отсутствие стратегии и
                  единого органа управления изменениями, знаний, компетенций и&nbsp;практического опыта,
                  дефицит квалифицированных кадров и&nbsp;внутреннее сопротивление сотрудников приводят к
                  необходимости обращаться к&nbsp;<span>внешним экспертам</span>.
                </div>
              </div>
            </div>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-8.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-8.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  Высокая скорость изменений в&nbsp;VUCA-реальности повышает роль стратегических{' '}
                  <span>цифровых партнерств</span> и&nbsp;M&amp;A, которые вместе с&nbsp;внутренними процессами в
                  компании помогают быстро и&nbsp;эффективно достигать желаемых результатов.
                </div>
              </div>
            </div>
            <div className="ch__mobile-sl">
              <div className="ch__mobile-sl-img">
                <img src={require('../assets/img/ch-9.jpg')} />
                <div className="ch__mobile-sl-numb">
                  <img src={require('../assets/img/ch-n-9.png')} />
                </div>
              </div>
              <div className="ch__mobile-sl-ct">
                <div className="ch__ct-it-title">Вызовы</div>
                <div className="sc__txt">
                  Дефицит цифровых лидеров, технологичных команд, способных результативно запускать
                  комплексные цифровые продукты, и&nbsp;целого ряда ограничений, приводит к&nbsp;необходимости
                  использования <span>внешней команды</span> для выстраивания и&nbsp;управления процессом
                  трансформации. Это упрощает процессы найма сотрудников, переговоров и&nbsp;проведения
                  сделок.
                </div>
              </div>
            </div>
          </Slider>
          <div className="ch__mobile-decor-box">
            <Slider
              asNavFor={this.state.nav1}
              ref={(slider5) => (this.slider5 = slider5)}
              {...sl5_set}>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
              <div className="ch__mobile-decor">
                <div className="ch__box-it">
                  <div className="ch__box-it-sl">
                    <div className="ch__box-it-sl-handle"></div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Header
          page={'home'}
          goToAnchor={this.goToAnchor}
          screen={this.state.screen}
          mode={this.state.screen > 0 ? 'small' : 'common'}
          ref={(instance) => {
            this.header = instance;
          }}
        />
        {this.state.isReady && (
          <ReactFullpage
            licenseKey={'K1JK9-R36BH-PQ5J9-8PE67-NWMYN'}
            anchors={anchors}
            responsiveWidth={1023}
            navigationTooltips={anchors}
            onLeave={(origin, destination, direction) => {
              this.setState({
                changing: true,
                screen: destination.index,
              });

              if (window.matchMedia('(max-width: 1023px)').matches) {
                if (destination.index === 2 && this.state.ab_slide === 1) {
                  let el = document.getElementById('disableScroll');
                  el.click();
                }

                if (destination.index === 2 && this.state.ab_slide === 3) {
                  let el = document.getElementById('disableScroll');
                  el.click();
                }

                if (destination.index === 5 && this.state.schema_slide === 1) {
                  let el = document.getElementById('disableScroll');
                  el.click();
                }

                if (destination.index === 5 && this.state.schema_slide === 3) {
                  let el = document.getElementById('disableScroll');
                  el.click();
                }
              }
            }}
            afterLoad={(origin, destination) => {
              this.setState({
                changing: false,
              });

              if (window.matchMedia('(max-width: 1023px)').matches) {
                switch (destination.index) {
                  case 2:
                    animMbAbout1.play();
                    break;
                }
              } else {
                switch (destination.index) {
                  case 1:
                    /**/

                    //animMission.play();
                    break;

                  case 2:
                    /*animAbout1.play();
                                        animAbout2.play();
                                        animAbout3.play();*/

                    break;

                  case 11:
                    gsap.to('.news__it', {
                      y: 0,
                      opacity: 1,
                      duration: 0.4,
                      delay: 0.15,
                      stagger: {
                        each: 0.15,
                      },
                    });

                    gsap.to('.load__more-wrap', {
                      y: 0,
                      opacity: 1,
                      duration: 0.4,
                      delay: 0.15,
                    });
                    break;

                  case 3:
                    animAct1.play();
                    animAct2.play();
                    break;

                  case 6:
                    animChallenges1.play();
                    animChallenges2.play();
                    break;

                  case 7:
                    animAbout1.play();
                    animAbout2.play();
                    animAbout3.play();
                    break;
                }
              }
            }}
            render={({ state, fullpageApi }) => {
              return (
                <>
                  <div className="section sc sc__box sc__promo">
                    <div className="container">
                      <div className="sc__promo-title">
                        <span id="pr-span-1">РАМТ-</span>
                        <span id="pr-span-2">
                          инвестиционно-
                        </span>
                        <br className="hidden-sm-up" />
                        <span id="pr-span-3">
                          проектное
                          <span id="pr-span-decor-2" />
                          <span id="pr-span-decor-3" />
                        </span>
                        &nbsp;
                        <span id="pr-span-4">
                          бюро
                          <span id="subtitle-line-1" className="subtitle-line" />
                          <span id="subtitle-line-2" className="subtitle-line" />
                          <span id="subtitle-line-3" className="subtitle-line" />
                          <span id="pr-span-decor-4" />
                        </span>
                      </div>
                      <div className="sc__promo-bottom d-flex align-items-start justify-content-between">
                        <div className="sc__promo-decor" aria-hidden="true" />
                        <div className="sc__promo-subtitle">
                          <span id="subtitle-1">с международной экспертизой в области</span>
                          <span id="subtitle-2">управления активами и инвестиционной</span>
                          <span id="subtitle-3">привлекательностью бизнеса</span>
                        </div>
                      </div>
                    </div>

                    <div className="hidden">
                      <button
                        id="goToBtn"
                        onClick={() => {
                          fullpageApi.moveTo(this.state.nav_sc);
                        }}
                      />
                      <button
                        id="disableScroll"
                        onClick={() => {
                          fullpageApi.setAllowScrolling(false);
                        }}
                      />
                      <button
                        id="enableScroll"
                        onClick={() => {
                          fullpageApi.setAllowScrolling(true);
                        }}
                      />
                      <button
                        id="moveScDown"
                        onClick={() => {
                          fullpageApi.moveSectionDown();
                        }}
                      />
                      <button
                        id="moveScUp"
                        onClick={() => {
                          fullpageApi.moveSectionUp();
                        }}
                      />
                    </div>
                  </div>
                  <div className="section sc sc__box sc__history">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title">Главное</div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="hist__data">
                        {/* <div className="hist__decor hidden-sm-down" /> */}
                        <div className="row">
                          <div className="hist__col">
                            <div className="hist__subtitle">
                              Появление РАМТ&nbsp;&mdash; это ответ на&nbsp;актуальные потребности
                              офлайн-компаний развиваться в&nbsp;условиях цифровой экономики, глобальных
                              изменений и&nbsp;многочисленных факторов нестабильности.
                            </div>
                            <div className="hist__sep" />
                            <div className="hist__descr">
                              <p>
                                РАМТ&nbsp;&mdash; единственное в&nbsp;России бюро, которое работает в&nbsp;уникальной
                                бизнес-нише по&nbsp;модели Sweat Equity&nbsp;&mdash; на&nbsp;пересечении инвестиционной,
                                консалтинговой и&nbsp;продуктовой деятельности.
                              </p>
                              <p>
                                Мы&nbsp;помогаем партнерам в&nbsp;классических индустриях адаптироваться к
                                постоянным изменениям внешней среды и&nbsp;становиться лидерами в&nbsp;своем
                                сегменте рынка. Создавая новый самостоятельный бизнес, мы&nbsp;разделяем
                                ответственность за&nbsp;результат. Сегодня клиентами РАМТ являются уже
                                более 10&nbsp;ведущих российских промышленных компаний.
                              </p>
                            </div>
                          </div>
                          <div className="hist__col hist__col--2">
                            <picture>
                              <source
                                srcSet={require('../assets/img/h-img-mb.jpg')}
                                media="(max-width: 1023px)"
                              />
                              <source srcSet={require('../assets/img/h-img-desk.jpg')} />
                              <img srcSet={require('../assets/img/h-img-desk.jpg')} alt="" />
                            </picture>
                          </div>
                          <div className="hist__col">
                            <div className="hist__box">
                              <div className="hist__title">
                                Бюро основано в&nbsp;апреле 2021 года Рафаэлем Абрамяном
                                и&nbsp;Максимом Тадевосяном,
                              </div>
                              <div className="hist__descr">
                                опытной командой топ-менеджеров с&nbsp;успешной историей запуска
                                эффективных технологических проектов.
                              </div>
                              <div className="hist__decor"></div>
                            </div>
                            <div className="hist__descr">
                              <p>
                                Для компаний из&nbsp;реального сектора экономики, IT-бизнеса и&nbsp;финансовых
                                институтов мы&nbsp;разрабатываем индивидуальные решения, увеличивающие их
                                капитализацию и&nbsp;конкурентоспособность через создание новых
                                бизнес-моделей, актуальных цифровых сервисов, а&nbsp;также поиск новых
                                точек роста и&nbsp;создание выгодных стратегических партнерств.
                              </p>
                            </div>
                          </div>
                          <div className="hist__col  hist__col--4">
                            <div className="hist__descr">
                              Нас отличает стратегическое видение, любовь к&nbsp;цифрам и&nbsp;аналитике, а
                              также обширный опыт работы на&nbsp;российском и&nbsp;международном рынках. Мы
                              внедряем изменения, которые приводят к&nbsp;росту бизнеса партнера, и
                              управляем ими до&nbsp;достижения желаемого результата. Все решения мы
                              принимаем только на&nbsp;базе анализа деятельности компании, исследований и
                              работе с&nbsp;данными.
                            </div>
                            <picture>
                              <source
                                srcSet={require('../assets/img/h-img-mb2.jpg')}
                                media="(max-width: 1023px)"
                              />
                              <source srcSet={require('../assets/img/h-img-desk2.jpg')} />
                              <img srcSet={require('../assets/img/h-img-desk2.jpg')} alt="" />
                            </picture>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section sc sc__box sc__why">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title">Почему РАМТ?</div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="sc__ct">
                        <div className="sc__header">
                          <div className="sc__title">Почему РАМТ?</div>
                        </div>
                        <div className="why__sl-box">
                          <div className="why__sl-row">
                            <Slider ref={(slider7) => (this.slider7 = slider7)} {...sl7_set}>
                              <div className="why__sl-it">
                                <div className="why__sl-it-bl d-flex align-items-start">
                                  <div className="why__sl-it-img">
                                    <img src={require('../assets/img/why-1.jpg')} />
                                  </div>
                                  <div className="why__sl-it-ct">
                                    <div className="why__sl-it-title">
                                      Имеем практический <br className="hidden-sm-up" />
                                      опыт работы
                                    </div>
                                    <div className="why__sl-it-numb">01</div>
                                    <div className="why__sl-it-sep" />
                                  </div>
                                </div>
                              </div>
                              <div className="why__sl-it">
                                <div className="why__sl-it-bl d-flex align-items-start">
                                  <div className="why__sl-it-img">
                                    <img src={require('../assets/img/why-2.jpg')} />
                                  </div>
                                  <div className="why__sl-it-ct">
                                    <div className="why__sl-it-title">
                                      Формируем эффективные технологические команды из&nbsp;России и
                                      стран СНГ
                                    </div>
                                    <div className="why__sl-it-numb">02</div>
                                    <div className="why__sl-it-sep" />
                                  </div>
                                </div>
                              </div>
                              <div className="why__sl-it">
                                <div className="why__sl-it-bl d-flex align-items-start">
                                  <div className="why__sl-it-img">
                                    <img src={require('../assets/img/why-3.jpg')} />
                                  </div>
                                  <div className="why__sl-it-ct">
                                    <div className="why__sl-it-title">
                                      Разрабатываем самостоятельные бизнес-продукты для решения
                                      любых задач
                                    </div>
                                    <div className="why__sl-it-numb">03</div>
                                    <div className="why__sl-it-sep" />
                                  </div>
                                </div>
                              </div>
                              <div className="why__sl-it">
                                <div className="why__sl-it-bl d-flex align-items-start">
                                  <div className="why__sl-it-img">
                                    <img src={require('../assets/img/why-4.jpg')} />
                                  </div>
                                  <div className="why__sl-it-ct">
                                    <div className="why__sl-it-title">
                                      Работаем только с&nbsp;проектами, в&nbsp;которые верим
                                    </div>
                                    <div className="why__sl-it-numb">04</div>
                                    <div className="why__sl-it-sep" />
                                  </div>
                                </div>
                              </div>
                              <div className="why__sl-it">
                                <div className="why__sl-it-bl d-flex align-items-start">
                                  <div className="why__sl-it-img">
                                    <img src={require('../assets/img/why-5.jpg')} />
                                  </div>
                                  <div className="why__sl-it-ct">
                                    <div className="why__sl-it-title">
                                      Сочетаем офлайн и&nbsp;онлайн-компетенции, экспертизу и&nbsp;технологии
                                    </div>
                                    <div className="why__sl-it-numb">05</div>
                                    <div className="why__sl-it-sep" />
                                  </div>
                                </div>
                              </div>
                              <div className="why__sl-it">
                                <div className="why__sl-it-bl d-flex align-items-start">
                                  <div className="why__sl-it-img">
                                    <img src={require('../assets/img/why-6.jpg')} />
                                  </div>
                                  <div className="why__sl-it-ct">
                                    <div className="why__sl-it-title">
                                      Управляем реальными изменениями в&nbsp;бизнесе партнеров
                                    </div>
                                    <div className="why__sl-it-numb">06</div>
                                    <div className="why__sl-it-sep" />
                                  </div>
                                </div>
                              </div>
                              <div className="why__sl-it">
                                <div className="why__sl-it-bl d-flex align-items-start">
                                  <div className="why__sl-it-img">
                                    <img src={require('../assets/img/why-7.jpg')} />
                                  </div>
                                  <div className="why__sl-it-ct">
                                    <div className="why__sl-it-title">
                                      Несем финансовую ответственность за&nbsp;результат вместе с
                                      партнером
                                    </div>
                                    <div className="why__sl-it-numb">07</div>
                                    <div className="why__sl-it-sep" />
                                  </div>
                                </div>
                              </div>
                            </Slider>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <div className="sl__nav-list sl__nav--why">
                            <div
                              onClick={() => {
                                this.slider7.slickPrev();
                              }}
                              className="sl__nav sl__nav-prev"
                            />
                            <div
                              onClick={() => {
                                this.slider7.slickNext();
                              }}
                              className="sl__nav sl__nav-next"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section sc sc__box sc__activities">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title">
                          Принципы нашей работы
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      {this.renderSchema()}
                      <div className="activities__list d-flex hidden-sm-down">
                        <div className="activities__it">
                          <div className="d-flex">
                            <div className="activities__it-numb">1</div>
                          </div>
                          <div className="activities__it-descr">
                            Погружение в&nbsp;бизнес партнера. Разрабатываем стратегию на&nbsp;основе бизнес-аудита компании.
                          </div>
                        </div>
                        <div className="activities__it">
                          <div className="d-flex">
                            <div className="activities__it-numb">2</div>
                          </div>
                          <div className="activities__it-descr">
                            Сочетание экспертизы RAMT и&nbsp;компетенций партнера. Создаем внутреннюю
                            команду по&nbsp;цифровой трансформации и&nbsp;обучаем ее&nbsp;работать самостоятельно.
                          </div>
                        </div>
                        <div className="activities__it">
                          <div className="d-flex">
                            <div className="activities__it-numb">3</div>
                          </div>
                          <div className="activities__it-descr">
                            Ответственность перед партнером. Заинтересованность в&nbsp;высоком финансовом
                            результате заложена в&nbsp;саму модель взаимоотношений.
                          </div>
                        </div>
                        <div className="activities__it">
                          <div className="d-flex">
                            <div className="activities__it-numb">4</div>
                          </div>
                          <div className="activities__it-descr">
                            Реальный результат. Результат партнерства&nbsp;&mdash; это готовый продукт с
                            перспективой дальнейшего коммерческого развития и&nbsp;новая капитальная
                            стоимость бизнеса.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.renderTargetSection()}
                  {/*<div className="section sc sc__box sc__quote">
                    <div className="d-flex section__scroll-box justify-content-end">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="quote__box d-flex align-items-start">
                        <div className="team__it-bl">
                          <img src={require('../assets/img/owner.jpg')} />
                          <div className="team__it-ct d-flex align-items-center">
                            <div className="team__it-ct-bl">
                              <div className="team__it-name">
                                Максим <br />
                                Тедевосян
                              </div>
                              <div className="team__it-position">
                                Сооснователь и <br />
                                управляющий <br />
                                партнер RAMT
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="quote__ct">
                          <div className="quote__icon">
                            <svg
                              width="107"
                              height="80"
                              viewBox="0 0 107 80"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M24.7723 51.5302L44.7964 51.5302C46.7066 51.5302 47.0313 51.1833 47.0313 49.138C47.0313 33.5231 47.0313 17.9063 47.0313 2.28743C47.0313 0.26305 46.813 0.00589343 45.0256 0.00589359C30.7302 0.00589484 16.433 0.00589609 2.13401 0.00589734C0.240212 0.0058975 0.00292272 0.266037 0.0029229 2.31732C0.00292427 17.984 0.0137396 33.6507 0.0355715 49.3174C0.0580858 50.1402 0.32444 50.9327 0.794257 51.575C7.86189 60.7191 14.9568 69.8382 22.079 78.9324C22.5975 79.5992 23.2142 80.3887 23.9892 79.7966C24.4531 79.4408 24.7369 78.4779 24.7396 77.7871C24.7915 69.6807 24.7723 61.5743 24.7723 53.4678L24.7723 51.5302ZM22.2782 74.5757C21.7516 74.0404 21.506 73.8371 21.3122 73.5889C15.2688 65.8463 9.2362 58.0937 3.21462 50.3311C2.82702 49.7476 2.62052 49.0433 2.62528 48.3217C2.59253 33.6577 2.58524 18.9947 2.60343 4.33275C2.60343 3.85432 2.65528 3.37886 2.68257 2.89744L44.3298 2.89743L44.3298 48.6895L24.6332 48.6895C22.4119 48.6895 22.2728 48.8479 22.2728 51.3478C22.2728 58.3528 22.2728 65.3608 22.2728 72.3719L22.2782 74.5757Z"
                                fill="#FF1EFF"
                              />
                              <path
                                d="M84.3945 51.5302L104.345 51.5301C106.334 51.5301 106.667 51.1803 106.667 49.0692C106.667 33.4504 106.667 17.8325 106.667 2.21565C106.667 0.275005 106.413 0.00288745 104.59 0.00288761C90.3387 0.00288886 76.0871 0.0028901 61.8354 0.00289135C59.8352 0.00289152 59.6523 0.221184 59.6523 2.39506C59.6523 18.0139 59.6641 33.6318 59.6878 49.2486C59.7054 50.1184 59.9813 50.9582 60.4737 51.6408C67.4831 60.7131 74.5225 69.7554 81.592 78.7679C81.8121 79.0836 82.0609 79.3742 82.3343 79.6351C83.1802 80.3527 84.2035 79.9341 84.3645 78.7649C84.4173 78.2218 84.4318 77.6749 84.4081 77.1293C84.4081 69.2192 84.4081 61.3111 84.4081 53.405L84.3945 51.5302ZM104.004 48.6895L102.219 48.6895C96.2302 48.6895 90.2388 48.6895 84.2445 48.6895C82.0778 48.6895 81.8948 48.8838 81.8948 51.2192C81.8948 58.3299 81.8949 65.4416 81.8949 72.5543C81.8949 73.1105 81.8512 73.6696 81.8157 74.5189C81.4037 74.1062 81.2236 73.9597 81.0845 73.7803C74.981 65.9559 68.8867 58.1205 62.8014 50.2742C62.438 49.7311 62.2464 49.0728 62.2556 48.3994C62.2229 33.6756 62.2156 18.9538 62.2338 4.23405C62.2338 3.80047 62.2829 3.36688 62.3129 2.88247L104.009 2.88247L104.004 48.6895Z"
                                fill="#FF1EFF"
                              />
                            </svg>
                          </div>
                          <div className="quote__title">
                            Создаем эффективные точки роста в ответ на изменения внешней среды
                          </div>
                          <div className="quote__text">
                            <p>
                              Вызов - это всегда точка роста для бизнеса. Повышение конкурентных
                              преимуществ требует постоянного интенсивного развития, поиска
                              нестандартных решений. Чтобы стать успешными в новых экономических
                              условиях, классическим индустриям важно оперативно реагировать на
                              происходящие изменения, создавая новые устойчивые бизнес-модели и
                              эффективные партнерства. Это длительный путь, требующий многогранной
                              экспертизы в разных сферах бизнеса, а также практического владения
                              современными цифровыми инструментами.
                            </p>
                            <p>
                              Проделать этот путь эффективно и безболезненно бизнесу помогает наша
                              команда. Мы определяем зоны развития, показываем, как повысить свою
                              конкурентность через внедрение цифровых технологий и управленческих
                              инноваций. Результат нашей работы - это готовый продукт, за
                              эффективность которого мы несем личную ответственность
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  <div className="section sc sc__box sc__principles">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title">Решения</div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="sc__ct">
                        <div className="prin__box d-flex">
                          <div className="prin__box-left">
                            <div
                              className={'prin__slide' + (this.state.prin == 0 ? ' active' : '')}>
                              <div className="prin__slide-img">
                                <img src={require('../assets/img/prin-bg-1.jpg')} />
                              </div>
                            </div>
                            <div
                              className={'prin__slide' + (this.state.prin == 1 ? ' active' : '')}>
                              <div className="prin__slide-img">
                                <img src={require('../assets/img/prin-bg-2.jpg')} />
                              </div>
                            </div>
                            <div
                              className={'prin__slide' + (this.state.prin == 2 ? ' active' : '')}>
                              <div className="prin__slide-img">
                                <img src={require('../assets/img/prin-bg-3.jpg')} />
                              </div>
                            </div>
                          </div>
                          <div className="prin__box-ct">
                            <Slider ref={(slider8) => (this.slider8 = slider8)} {...sl8_set}>
                              <div className="prin__it">
                                <div className="prin__numb">
                                  <img src={require('../assets/img/n-1.png')} />
                                </div>
                                <div className="prin__it-title">
                                  Инвестиционная <br />
                                  деятельность
                                </div>
                                <div className="det__list">
                                  <div className="row">
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Управление инвестициями в&nbsp;сфере цифровых проектов для
                                        непрофильных инвесторов
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Привлечение инвестиций в&nbsp;перспективные проекты в&nbsp;цифровой
                                        среде в&nbsp;перспективе 3-5 лет
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Привлечение инвестиций в&nbsp;проекты с&nbsp;потенциалом трансформации
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="prin__it">
                                <div className="prin__numb">
                                  <img src={require('../assets/img/n-2.png')} />
                                </div>
                                <div className="prin__it-title">Управляющая компания</div>
                                <div className="det__list">
                                  <div className="row">
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Разработка стратегии технологических инвестиций, сделок M&amp;A
                                        и&nbsp;акселерации команд в&nbsp;технологических отраслях
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Управление цифровыми активами непрофильных инвесторов
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Развитие портфельных активов с&nbsp;целью M&amp;A.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="prin__it prin__it--3">
                                <div className="prin__numb">
                                  <img src={require('../assets/img/n-3.png')} />
                                </div>
                                <div className="prin__it-title">Трансформация бизнеса</div>
                                <div className="det__list det__list--4">
                                  <div className="row">
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Внедрение современных технологий в&nbsp;бизнес-процессы партнеров
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Проведение аудита, определение точек роста
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Формирование технологических команд
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Построение индивидуальной бизнес-модели
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Разработка индивидуальных продуктов
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Создание цифровых партнерств и&nbsp;новых каналов сбыта
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Создание новых моделей монетизации
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">
                                        Повышение операционной деятельности
                                      </div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">Разработка стратегии</div>
                                    </div>
                                    <div className="det__it">
                                      <div className="det__it-bl">Управление изменениями</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Slider>
                          </div>
                          <div className="sl__nav-list sl__nav--why">
                            <div
                              onClick={() => {
                                this.slider8.slickPrev();
                              }}
                              className="sl__nav sl__nav-prev"
                            />
                            <div
                              onClick={() => {
                                this.slider8.slickNext();
                              }}
                              className="sl__nav sl__nav-next"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section sc__ind">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title">Индустрии</div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="ind__list">
                        <div className="row m-0">
                          <div className="ind__it ind__it--header hidden-sm-down">
                            <div>
                              <div className="sc__title">Индустрии</div>
                              <div className="ind__decor" />
                            </div>
                          </div>
                          <div className="ind__it ind__it--bg">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-1.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-1"></div>
                            <div className="ind__it-title">Промышленность</div>
                          </div>
                          <div className="ind__it ind__it--2">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-2.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-2"></div>
                            <div className="ind__it-title">
                              Топливо <br />и Энергетика
                            </div>
                          </div>
                          <div className="ind__it ind__it--3">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-3.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-3"></div>
                            <div className="ind__it-title">
                              Сельское <br />
                              хозяйство
                            </div>
                          </div>
                          <div className="ind__it ind__it--4">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-4.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-4"></div>
                            <div className="ind__it-title">
                              Транспорт <br />и Логистика
                            </div>
                          </div>
                          <div className="ind__it">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-5.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-5"></div>
                            <div className="ind__it-title">Машиностроение</div>
                          </div>
                          <div className="ind__it">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-6.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-6"></div>
                            <div className="ind__it-title">Финансы</div>
                          </div>
                          <div className="ind__it">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-7.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-7"></div>
                            <div className="ind__it-title">Телеком</div>
                          </div>
                          <div className="ind__it ind__it--bg">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-8.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-8"></div>
                            <div className="ind__it-title">Строительство</div>
                          </div>
                          <div className="ind__it ind__it--9">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-9.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-9"></div>
                            <div className="ind__it-title">Розничная торговля</div>
                          </div>
                          <div className="ind__it">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-10.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-10"></div>
                            <div className="ind__it-title">
                              Медицина <br />и Фарма
                            </div>
                          </div>
                          <div className="ind__it ind__it--bg">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-11.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-11"></div>
                            <div className="ind__it-title">Технологии, Медиа, Развлечения</div>
                          </div>
                          <div className="ind__it">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-12.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-12"></div>
                            <div className="ind__it-title">Переработка вторсырья</div>
                          </div>
                          <div className="ind__it">
                            <div className="ind__it-img">
                              <img src={require('../assets/img/ind-13.jpg')} />
                            </div>
                            <div className="ico-ind ico-ind-13"></div>
                            <div className="ind__it-title">Возобновляемые ресурсы</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section sc sc__box sc__challenges">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title">
                          Принципы нашей работы
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="ch__ct-box d-flex align-items-center hidden-sm-down">
                        <div className="ch__box-left d-flex justify-content-between">
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 0 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-1.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(0);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 1 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-2.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(1);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 2 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-3.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(2);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 3 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-4.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(3);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 4 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-5.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(4);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 5 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-6.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(5);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 6 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-7.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(6);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 7 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-8.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(7);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                          <div
                            className={'ch__box-it ' + (this.state.ch_cur === 8 ? 'active' : '')}>
                            <div className="ch__box-it-img-box">
                              <div className="ch__box-it-img">
                                <img src={require('../assets/img/ch-9.jpg')} />
                              </div>
                            </div>
                            <div className="ch__box-it-sl">
                              <div
                                onClick={() => {
                                  this.slider1.slickGoTo(8);
                                }}
                                className="ch__box-it-sl-handle"></div>
                            </div>
                          </div>
                        </div>
                        <div className="ch__box-right">
                          <div
                            onClick={() => {
                              this.slider1.slickPrev();
                            }}
                            className="ch__box-nav ch__box-nav--prev"></div>
                          <div
                            onClick={() => {
                              this.slider1.slickNext();
                            }}
                            className="ch__box-nav ch__box-nav--next"></div>
                          <div className="ch__ct-sl">
                            <Slider ref={(slider1) => (this.slider1 = slider1)} {...sl_set}>
                              <div className="ch__ct-it ch__ct-it--1">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-1.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  <span>92/8</span> - диспропорция соотношения онлайн и&nbsp;офлайн
                                  бизнеса в&nbsp;России. Сегодня цифровой бизнес составляет лишь{' '}
                                  <span>8%</span>, но&nbsp;оказывает существенное влияние на
                                  классические индустрии.
                                </div>
                              </div>
                              <div className="ch__ct-it ch__ct-it--2">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-2.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  Лишь <span>8%</span> компаний считают, что их&nbsp;бизнес-модель устоит
                                  в&nbsp;условиях растущей цифровизации (по&nbsp;данным глобального{' '}
                                  <a href="#" target="_blank">
                                    опроса
                                  </a>{' '}
                                  McKinsey)
                                </div>
                              </div>
                              <div className="ch__ct-it ch__ct-it--3">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-3.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  Большинство крупных компаний, образованных еще в&nbsp;20&nbsp;веке, по
                                  традиционным классическим моделям не&nbsp;способны к&nbsp;быстрым и&nbsp;гибким
                                  изменениям по&nbsp;своей сути. Именно поэтому они все меньше
                                  соответствуют той внешней среде, в&nbsp;которой им&nbsp;приходится
                                  существовать.
                                </div>
                              </div>
                              <div className="ch__ct-it ch__ct-it--4">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-4.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  Сегодня компании во&nbsp;всех сферах осознают важность поиска новых
                                  точек роста, увеличение скорости адаптации к&nbsp;внешним изменениям и
                                  повышение конкурентоспособности как залога сохранения бизнеса.
                                  Первыми об&nbsp;этом традиционно задумались наиболее гибкие и&nbsp;высоко
                                  адаптивные бизнесы&nbsp;&mdash; IT, логистика, финтех, ритейл и&nbsp;e-commerce.
                                </div>
                              </div>
                              <div className="ch__ct-it ch__ct-it--5">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-5.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  <span>Общие факторы</span>, способствующие перестройке бизнеса
                                  независимо от&nbsp;его размера, отраслевой и&nbsp;географической
                                  принадлежности&nbsp;&mdash; это новые технологии, международные политические
                                  и&nbsp;экономические процессы, климатические и&nbsp;экологические изменения,
                                  смена поколений и&nbsp;их&nbsp;потребительских моделей, а&nbsp;также общемировой
                                  дефицит квалифицированных кадров.
                                </div>
                              </div>
                              <div className="ch__ct-it ch__ct-it--6">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-6.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  Любые изменения в&nbsp;компании должны происходить строго в&nbsp;условиях
                                  индивидуального подхода. Попытки копирования лучших практик с
                                  рынка обычно оказываются ошибочным решением. Даже в&nbsp;одной сфере
                                  крупным компаниям с&nbsp;уникальными бизнес-процессами требуются{' '}
                                  <span>индивидуальные решения</span>, основанные на&nbsp;множестве
                                  факторов.
                                </div>
                              </div>
                              <div className="ch__ct-it ch__ct-it--7">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-7.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  <span>Большая часть</span> попыток бизнеса трансформировать свою
                                  модель и&nbsp;адаптироваться к&nbsp;новым реалиям заканчиваются неудачей.
                                  Отсутствие стратегии и&nbsp;единого органа управления изменениями,
                                  знаний, компетенций и&nbsp;практического опыта, дефицит
                                  квалифицированных кадров и&nbsp;внутреннее сопротивление сотрудников
                                  приводят к&nbsp;необходимости обращаться&nbsp;к{' '}
                                  <span>внешним экспертам</span>.
                                </div>
                              </div>
                              <div className="ch__ct-it ch__ct-it--8">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-8.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  Высокая скорость изменений в&nbsp;VUCA-реальности повышает роль
                                  стратегических <span>цифровых партнерств</span> и&nbsp;M&amp;A, которые
                                  вместе с&nbsp;внутренними процессами в&nbsp;компании помогают быстро и
                                  эффективно достигать желаемых результатов.
                                </div>
                              </div>
                              <div className="ch__ct-it ch__ct-it--9">
                                <div className="ch__ct-it-numb">
                                  <img src={require('../assets/img/ch-n-9.png')} />
                                </div>
                                <div className="ch__ct-it-title">Вызовы</div>
                                <div className="sc__txt">
                                  Дефицит цифровых лидеров, технологичных команд, способных
                                  результативно запускать комплексные цифровые продукты, и&nbsp;целого
                                  ряда ограничений, приводит к&nbsp;необходимости использования{' '}
                                  <span>внешней команды</span> для выстраивания и&nbsp;управления
                                  процессом трансформации. Это упрощает процессы найма сотрудников,
                                  переговоров и&nbsp;проведения сделок.
                                </div>
                              </div>
                            </Slider>
                          </div>
                        </div>
                      </div>
                      <div className="sl__pager d-flex justify-content-center hidden-sm-down">
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(0);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 0 ? 'active' : '')
                          }>
                          01
                        </div>
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(1);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 1 ? 'active' : '')
                          }>
                          02
                        </div>
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(2);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 2 ? 'active' : '')
                          }>
                          03
                        </div>
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(3);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 3 ? 'active' : '')
                          }>
                          04
                        </div>
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(4);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 4 ? 'active' : '')
                          }>
                          05
                        </div>
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(5);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 5 ? 'active' : '')
                          }>
                          06
                        </div>
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(6);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 6 ? 'active' : '')
                          }>
                          07
                        </div>
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(7);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 7 ? 'active' : '')
                          }>
                          08
                        </div>
                        <div
                          onClick={() => {
                            this.slider1.slickGoTo(8);
                          }}
                          className={
                            'sl__pager-it d-flex align-items-center justify-content-center ' +
                            (this.state.ch_cur === 8 ? 'active' : '')
                          }>
                          09
                        </div>
                      </div>

                      {this.renderChallengesMobile()}
                    </div>
                  </div>
                  <div className="section sc sc__box sc__about">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow">

                        </div>
                        <div className="scroll__down-title">
                          Бизнес-модель
                        </div>
                      </div>
                    </div>
                    {this.renderAbBox()}
                  </div>
                  {/*<div className="section sc sc__model">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title text-right">Бизнес-модель</div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="sc__ct">
                        <div className="sc__header hidden-sm-down">
                          <div className="sc__title">Бизнес-модель</div>
                        </div>
                        <div className="model__list">
                          <div className="row">
                            <div className="model__it model__it--dark">
                              <div className="model__it-bl">
                                <div className="model__it-title">
                                  Мы работаем по модели капитализации проектов Sweat Equity.
                                </div>
                                <div className="model__it-descr">
                                  Это инвестиции в компанию партнера экспертизой, широкими связями и
                                  интеллектуальным капиталом вместо финансового. Мы раскрываем
                                  потенциал бизнеса и повышаем его ценность в обмен на долю. Именно
                                  поэтому нам важно добиться высокого коммерческого результата
                                  вместе с собственником. Это кропотливый и длительный путь, ведущий
                                  к увеличению стоимости компании.
                                </div>
                              </div>
                            </div>
                            <div className="model__it">
                              <div className="model__it-bl">
                                <div className="model__it-title">
                                  Sweat Equity имеет многолетнюю доказанную эффективность в США.
                                </div>
                                <div className="model__it-descr">
                                  Особенно часто эта модель применяется в работе со стартапами в
                                  условиях ограниченного финансирования. Немонетарные инвестиции
                                  помогают бизнесу привлекать и удерживать лучших сотрудников,
                                  компенсируя их усилия и вклад в процесс. Стоимость доли в капитале
                                  растет по мере роста бизнеса, поэтому работа в рамках Sweat Equity
                                  - это стимул для всех участников обеспечивать стабильное развитие
                                  компании.
                                </div>
                              </div>
                            </div>
                            <div className="model__it">
                              <div className="model__it-bl">
                                <div className="model__it-title">Как это работает на практике?</div>
                                <div className="model__it-descr">
                                  Опираясь на конкретный запрос, мы анализируем состояние компании,
                                  текущие бизнес-процессы, определяем точки роста,  разрабатываем
                                  стратегию и индивидуальный цифровой продукт. Для его запуска мы
                                  интегрируемся в бизнес партнера - формируем эффективную команду из
                                  экспертов RAMT и персонала заказчика, который обучаем
                                  самостоятельно управлять изменениями. В нашем партнерстве не может
                                  быть рисков, мы придерживаемся подхода - вознаграждение только за
                                  достижение результата.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  <div className="section sc__team-top">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title text-right">Команда</div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="team__top-box d-flex align-items-start">
                        <div className="team__top-left">
                          <div className="team__decor-list d-flex align-items-center">
                            <div className="team__decor-1"></div>
                            <div className="team__decor-2">
                              <svg
                                width="164"
                                height="36"
                                viewBox="0 0 164 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M141.128 1.62732L161.646 18.7257L141.145 34.3455L141.751 35.1409L162.751 19.1409L163.25 18.7607L162.768 18.3591L141.768 0.8591L141.128 1.62732ZM160.75 18.2434H0.75V19.2434H160.75V18.2434Z"
                                  fill="#626262"
                                />
                              </svg>
                            </div>
                            <div className="team__decor-3"></div>
                          </div>
                          <div className="team__top-title">
                            Многолетняя практика команды, соединенная со&nbsp;стратегическим видением
                            и&nbsp;лучшими цифровыми инструментами
                          </div>
                        </div>
                        <div className="team__top-right">
                          <div className="team__line hidden-sm-down" />
                          <div className="sc__promo-decor" />
                          <div className="team__top-descr">
                            Почему десятки партнеров доверяют нам свой бизнес? RAMT&nbsp;&mdash; это команда
                            визионеров с&nbsp;опытом работы в&nbsp;консалтинге, промышленности,
                            государственных структурах, финансах, рекламе и&nbsp;коммуникациях,
                            электронной коммерции, индустрии развлечений и&nbsp;кино. За&nbsp;нашими плечами
                            длинный список успешно запущенных проектов.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="section sc__team">
                    <div className="d-flex section__scroll-box hidden-sm-down">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="team__box">
                        {/*<div onClick={()=>{
                                                this.slider6.slickPrev();
                                            }} className="sl__nav sl__nav-prev"/>
                                            <div onClick={()=>{
                                                this.slider6.slickNext();
                                            }} className="sl__nav sl__nav-next"/>*/}
                        <div className="team__box-row">
                          <Slider ref={(slider) => (this.slider6 = slider)} {...sl6_set}>
                            <div className="team__it">
                              <div className="team__it-bl">
                                <img src={require('../assets/img/t-m-1.jpg')} />
                                <div
                                  onClick={() => {
                                    this.modals.team2Show();
                                  }}
                                  className="team__it-ct d-flex align-items-center">
                                  <div className="team__it-ct-bl">
                                    <div className="team__it-name">
                                      Рафаэль <br />
                                      Абрамян
                                    </div>
                                    <div className="team__it-position">
                                      Сооснователь и&nbsp;управляющий партнер RAMT
                                    </div>
                                    <div className="team__it-arr">
                                      <img src={require('../assets/img/arr-right-2.png')} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="team__it">
                              <div className="team__it-bl">
                                <img src={require('../assets/img/t-m-2.jpg')} />
                                <div
                                  onClick={() => {
                                    this.modals.team1Show();
                                  }}
                                  className="team__it-ct d-flex align-items-center">
                                  <div className="team__it-ct-bl">
                                    <div className="team__it-name">
                                      Максим <br />
                                      Тедевосян
                                    </div>
                                    <div className="team__it-position">
                                      Сооснователь RAMT
                                    </div>
                                    <div className="team__it-arr">
                                      <img src={require('../assets/img/arr-right-2.png')} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Slider>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div className="section sc sc__box sc__news">
                    <div className="d-flex section__scroll-box">
                      <div className="scroll__down d-flex align-items-end">
                        <div className="scroll__down-arrow"></div>
                        <div className="scroll__down-title">Новости</div>
                      </div>
                    </div>
                    <div className="container">{this.renderNewsBox()}</div>
                  </div>*/}
                  {this.renderContactsSection()}
                  <div className="section sc__footer fp-auto-height">
                    <Footer showSbscrModal={this.showSbscrModal} />
                  </div>
                </>
              );
            }}
          />
        )}

        <Modals
          ref={(instance) => {
            this.modals = instance;
          }}
        />
      </>
    );
  }
}

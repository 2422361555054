import React from 'react'
import "../assets/fonts/stylesheet.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/animate.css"
import "slick-carousel/slick/slick.css"
import "../assets/css/style.scss"
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Home2 from './_Home'
import Post from './Post'
import Subscribe from './Subscribe'
require('es6-promise').polyfill();
require('isomorphic-fetch');

export default class Main extends React.Component {
    render(){
        return(
            <Switch>
                <Route exact path='/' component={Home}/>
                <Route exact path='/home2' component={Home2}/>
                <Route exact path='/news/:slug' component={Post}/>
                <Route exact path='/subscribe' component={Subscribe}/>
            </Switch>
        )
    }
}

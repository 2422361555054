import React from 'react'
import { Link} from 'react-router-dom'
import {Helmet} from "react-helmet"
import ReactWOW from 'react-wow';
import Slider from "react-slick"
import CustomScroll from 'react-custom-scroll';
import {isMobile, isIOS, isAndroid} from 'react-device-detect'
import disableScroll from 'disable-scroll';
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from './Footer'
import Header from './Header'

gsap.registerPlugin(ScrollTrigger);

let sl_set = {}, sl2_set = {}, sl3_set = {};

export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            ch_cur: 0,
            full: false,
            slug: props.match.params.slug,
            prev_path: '/'
        }
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
        document.body.classList.add('page-not-front');
    }

    componentWillUnMount(){
        document.body.classList.remove('page-not-front');
    }

    handleScroll = (e) => {
        this.setState({
            full: true
        }, ()=>{
            setTimeout(function (){
                disableScroll.off();
            }, 1000);
        });
    }

    goToAnchor = (anchor) => {
        let el = document.getElementById(anchor);
        el.scrollIntoView({
            behavior: 'smooth'
        })
    }

    renderPostBody = () => {

        let body = (<div className="post__scroll-inside"/> );

        switch(this.state.slug){
            case 'byvshie-top-menedzhery-rambler-zapustili-tsifrovoi-startap':
                body = (
                    <div className="post__scroll-inside" onWheel={(e) => {
                        this.handleScroll(e);
                    }}
                         onMouseEnter={()=>{
                             if(!this.state.full){
                                 disableScroll.on();
                             }
                         }}
                         onMouseLeave={()=>{
                             disableScroll.off();
                         }}
                    >
                        <div className={'post__hd ' + ((this.state.full) ? 'active' : '')}>
                            <div className="post__img">
                                <img src={require("../assets/img/post.jpg")}/>
                            </div>
                            <div className="post__hd-ct">
                                <div className="post__info d-flex align-items-center">
                                    <div className="news__it-likes d-flex align-items-start">
                                        <i className="icon__likes"/>
                                        <span className="news__it-likes-info">
                                    420
                                </span>
                                    </div>
                                    <div className="news__it-comments">
                                        128 comments
                                    </div>
                                </div>
                                <div className="post__title seo__item">
                                    <h1>
                                        Бывшие топ-менеджеры Rambler запустили цифровой стартап
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className={'post__load-link hidden-sm-down ' + ((this.state.full) ? 'hidden' : '')} onClick={()=>{
                            this.setState({
                                full: true
                            }, () => {
                                this.goToAnchor('post');
                            })
                        }}/>
                        <div className={'post__body animated fadeIn ' + ((this.state.full) ? 'active' : '')}>
                            <div className="row row__custom justify-content-between">
                                <div className="col col-lg-3 col-12 hidden-sm-down">
                                    <div className="post__details-mb d-flex align-items-center justify-content-between hidden-sm-up">
                                        <div className="post__author">
                                            Рафаэль Абрамян и Максим Тадевосян будут развивать цифровые проекты для компаний реального сектор
                                        </div>
                                        <div className="post__date">
                                            30 April, 2022
                                        </div>
                                    </div>
                                    <div className="d-flex hidden-sm-down">
                                        <a href="#" className="news__it-social">
                                            f
                                        </a>
                                    </div>
                                    <div className="post__actions d-flex">
                                        <a href="#" className="post__action">
                                            <div className="post__action-icon d-flex align-items-center justify-content-center">
                                                <i className="icon__share">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.22 37.27">
                                                        <path d="M6.72,25.35a6.72,6.72,0,1,1,6.72-6.72,6.73,6.73,0,0,1-6.72,6.72m0-11.44a4.72,4.72,0,1,0,4.72,4.72,4.73,4.73,0,0,0-4.72-4.72"/>
                                                        <path d="M26.5,13.44a6.72,6.72,0,1,1,6.72-6.72,6.73,6.73,0,0,1-6.72,6.72M26.5,2a4.72,4.72,0,1,0,4.72,4.72A4.73,4.73,0,0,0,26.5,2"/>
                                                        <path d="M26.5,37.27a6.72,6.72,0,1,1,6.72-6.72,6.73,6.73,0,0,1-6.72,6.72m0-11.44a4.72,4.72,0,1,0,4.72,4.72,4.73,4.73,0,0,0-4.72-4.72"/>
                                                        <path d="M11.82,16.57a1,1,0,0,1-.52-1.86l9.8-5.87a1,1,0,0,1,1,1.72l-9.8,5.87a1,1,0,0,1-.51.14"/>
                                                        <path d="M21.62,28.56a1,1,0,0,1-.51-.14l-10-6a1,1,0,0,1,1-1.71l10,6a1,1,0,0,1-.51,1.86"/>
                                                    </svg>
                                                </i>
                                            </div>
                                            <div className="post__action-title">
                                                Share
                                            </div>
                                        </a>
                                        <a href="#" className="post__action">
                                            <div className="post__action-icon d-flex align-items-center justify-content-center">
                                                <i className="icon__download">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 37.08">
                                                        <path d="M34,37.08H1a1,1,0,0,1,0-2H34a1,1,0,0,1,0,2" transform="translate(0)"/>
                                                        <path d="M17.5,32.8h0a1,1,0,0,1-.81-.41L3.81,14.68a1,1,0,0,1,1.14-1.53L10.14,15V1a1,1,0,0,1,1-1H23.85a1,1,0,0,1,1,1V15L30,13.15a1,1,0,0,1,1.14,1.53L18.31,32.38a1,1,0,0,1-.81.41M7.31,16.09l10.19,14,10.19-14-3.51,1.22a1,1,0,0,1-1.33-.94V2H12.14V16.37a1,1,0,0,1-.42.82,1,1,0,0,1-.91.13Z"/>
                                                    </svg>
                                                </i>
                                            </div>
                                            <div className="post__action-title">
                                                Download
                                            </div>
                                        </a>
                                        <a href="#" className="post__action">
                                            <div className="post__action-icon d-flex align-items-center justify-content-center">
                                                <i className="icon__print">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.04 34.53">
                                                        <path d="M33,31.14H26.85a1,1,0,0,1-1-1V25.75H8.19v4.39a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V11.27a1,1,0,0,1,1-1H33a1,1,0,0,1,1,1V30.14a1,1,0,0,1-1,1m-5.19-2H32V12.27H2V29.14H6.19V24.75a1,1,0,0,1,1-1H26.85a1,1,0,0,1,1,1Z"/>
                                                        <path d="M25.21,11.77H8.84a.5.5,0,0,1-.5-.5V.5a.5.5,0,0,1,.5-.5H25.21a.5.5,0,0,1,.5.5V11.27a.5.5,0,0,1-.5.5m-15.87-1H24.71V1H9.34Z"/>
                                                        <path d="M24.19,34.53H9.85a.5.5,0,0,1-.5-.5v-6.7a.5.5,0,0,1,.5-.5H24.19a.5.5,0,0,1,.5.5V34a.5.5,0,0,1-.5.5m-13.84-1H23.69v-5.7H10.35Z"/>
                                                        <path d="M5,17.12A2.07,2.07,0,1,1,7,15.05,2.07,2.07,0,0,1,5,17.12M5,14A1.07,1.07,0,1,0,6,15.05,1.07,1.07,0,0,0,5,14"/>
                                                    </svg>
                                                </i>
                                            </div>
                                            <div className="post__action-title">
                                                Print
                                            </div>
                                        </a>
                                        <a href="#" className="news__it-social hidden-sm-up">
                                            f
                                        </a>
                                    </div>
                                    <div className="post__author hidden-sm-down">
                                        Рафаэль Абрамян и Максим Тадевосян будут развивать цифровые проекты для компаний реального сектор
                                    </div>
                                </div>
                                <div className="col col-lg-8 col-12">
                                    <div className="post__text">
                                        <p>
                                            О создании инвестиционно-конструкторского бюро РАМТ «Ведомостям» рассказали его основатели – бывшие топ-менеджеры медиахолдинга Rambler Рафаэль Абрамян и Максим Тадевосян. ООО «РАМТ», по данным «СПАРК-Интерфакса», они зарегистрировали в январе 2021 г., компания принадлежит им в равных долях. РАМТ планирует оказывать услуги, связанные с запуском и развитием цифровых проектов для компаний из реального сектора экономики, бизнес которых сосредоточен в офлайн-сегменте. Речь идет о разработке стратегии и бизнес-планов, запуске и выведении новых цифровых проектов на целевые показатели, а также повышении операционной эффективности уже существующих, рассказал Тадевосян.
                                        </p>
                                        <p>
                                            Совладельцы РАМТ в 2013–2020 гг. работали на руководящих должностях в медиахолдинге Rambler, но покинули компанию, после того как в прошлом году «Сбер» выкупил ее у Александра Мамута. Абрамян в декабре 2020 г. ушел с должности гендиректора Rambler Group. Тадевосян в начале марта 2021 г. покинул позицию гендиректора Rambler & Co, подразделения Rambler Group, объединяющего ее медиаактивы.
                                        </p>
                                        <p>
                                            Партнеры рассчитывают запускать «изолированные цифровые проекты», реализацией которых займутся совместные предприятия компании-заказчика и РАМТ. В этих СП РАМТ планирует получать миноритарные доли, а развитие проектов будет осуществляться на средства партнера-мажоритария. Предполагаемые функции РАМТ в СП – подбор команды и выстраивание бизнес-процессов. Штат управляющей компании РАМТ составит до 10 человек в первый год работы, ее деятельность Абрамян и Тадевосян будут финансировать из собственных средств. Объем инвестиций в проект с их стороны партнеры не раскрывают.
                                        </p>
                                        <p>
                                            Сейчас РАМТ заканчивает формирование пула из четырех проектов, утверждает Абрамян. Речь идет о партнерстве с предприятиями тяжелой промышленности, индустрии развлечений и ритейла. По его словам, с двумя компаниями РАМТ уже заключила контракты, еще с двумя достигла базовых договоренностей и обсуждает условия сотрудничества. Подробности этих проектов он раскрыть отказался, сославшись на подписанные соглашения о конфиденциальности.
                                        </p>
                                        <p>
                                            Абрамян и Тадевосян объясняют, что РАМТ будет работать по так называемой модели sweat equity. В таких случаях одна из сторон вкладывает в проект не деньги, а ключевые компетенции. РAMT интересуют проекты, капитализация которых в перспективе трех лет может достичь $100 млн, говорят они. Их компания рассчитывает входить в такие проекты на правах управляющего партнера в обмен на долю в капитале 15–25% в расчете на ее будущую продажу.
                                        </p>
                                        <p>
                                            Спрос на проекты в области цифровизации высок как в России, так и по всему миру, считает гендиректор дата-компании «Эвотор», акционер Run Capital Андрей Романенко. «Прошлый год с его локдаунами и изоляцией стал катализатором цифровизации», – отмечает он. Спрос на цифровую трансформацию есть во всех отраслях, это необходимое условие функционирования крупного и среднего бизнеса в текущих реалиях, соглашается старший аналитик «Ренессанс капитала» Александр Венгранович.
                                        </p>
                                        <p>
                                            В кадровых агентствах говорят о высоком спросе на управленческие команды, специализирующиеся на цифровых проектах. «Спрос на их услуги опережает рост технологических компаний», – утверждает старший партнер Korn Ferry Russia Антон Стороженко. Самая большая проблема для цифровых проектов – дефицит качественных управленческих команд, признает управляющий партнер московского офиса Magnum Hunt Executive Search Юлия Крижевич. По ее мнению, предлагаемый РАМТ подход помогает решить эту проблему.
                                        </p>
                                        <p>
                                            Однако эксперты разошлись в оценке бизнес-модели РАМТ. Романенко называет ее «свежей», отмечая, что она предполагает разделение ответственности за бизнес. Это выгодно отличает РАМТ от консультантов, внешних подрядчиков и наемных лидеров, считает он. В пользу основателей РАМТ говорит их профессионализм, но в данной бизнес-модели самое сложное – довести до значимого результата первые кейсы, сказал партнер Untitled Ventures Игорь Лутц.
                                        </p>
                                        <p>
                                            В свою очередь независимый директор, эксперт по цифровой стратегии и трансформационным проектам Денис Собе-Панек считает идею получения доли в бизнесе в обмен на компетенции утопичной. Он уверен, что, с точки зрения владельца перспективного бизнеса, проще и дешевле купить услуги консультантов или привлечь финансового инвестора со связями и ресурсами.
                                        </p>
                                        <p>
                                            Управляющий партнер консалтинговой группы VDI Илья Хоффман уточняет: при запросе опциона в объеме 15–25%, о котором говорят основатели РАМТ, неминуемо встает вопрос о соинвестировании, если, конечно, речь не идет о совсем уж небольших проектах и стартапах. «Без собственных инвестиций [со стороны РАМТ] реалистичный опцион в такой модели составляет от 3 до 10% с вестингом (постепенным переходом доли. – «Ведомости») в течение 3–5 лет при выполнении KPI, тем более что новая команда хочет одновременно консультировать до четырех разных заказчиков», – считает он.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
            case 'forbes-sobiraet-sovety':
                body = (
                    <div className="post__scroll-inside" onWheel={(e) => {
                        this.handleScroll(e);
                    }}
                         onMouseEnter={()=>{
                             if(!this.state.full){
                                 disableScroll.on();
                             }
                         }}
                         onMouseLeave={()=>{
                             disableScroll.off();
                         }}
                    >
                        <div className={'post__hd ' + ((this.state.full) ? 'active' : '')}>
                            <div className="post__img">
                                <img src={require("../assets/img/post.jpg")}/>
                            </div>
                            <div className="post__hd-ct">
                                <div className="post__info d-flex align-items-center">
                                    <div className="news__it-likes d-flex align-items-start">
                                        <i className="icon__likes"/>
                                        <span className="news__it-likes-info">
                                    420
                                </span>
                                    </div>
                                    <div className="news__it-comments">
                                        128 comments
                                    </div>
                                </div>
                                <div className="post__title seo__item">
                                    <h1>
                                        Forbes собирает советы
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className={'post__load-link hidden-sm-down ' + ((this.state.full) ? 'hidden' : '')} onClick={()=>{
                            this.setState({
                                full: true
                            }, () => {
                                this.goToAnchor('post');
                            })
                        }}/>
                        <div className={'post__body animated fadeIn ' + ((this.state.full) ? 'active' : '')}>
                            <div className="row row__custom justify-content-between">
                                <div className="col col-lg-3 col-12 hidden-sm-down">
                                    <div className="post__details-mb d-flex align-items-center justify-content-between hidden-sm-up">
                                        <div className="post__author">
                                            Рафаэль Абрамян и Максим Тадевосян будут развивать цифровые проекты для компаний реального сектор
                                        </div>
                                        <div className="post__date">
                                            30 April, 2022
                                        </div>
                                    </div>
                                    <div className="d-flex hidden-sm-down">
                                        <a href="#" className="news__it-social">
                                            f
                                        </a>
                                    </div>
                                    <div className="post__actions d-flex">
                                        <a href="#" className="post__action">
                                            <div className="post__action-icon d-flex align-items-center justify-content-center">
                                                <i className="icon__share">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.22 37.27">
                                                        <path d="M6.72,25.35a6.72,6.72,0,1,1,6.72-6.72,6.73,6.73,0,0,1-6.72,6.72m0-11.44a4.72,4.72,0,1,0,4.72,4.72,4.73,4.73,0,0,0-4.72-4.72"/>
                                                        <path d="M26.5,13.44a6.72,6.72,0,1,1,6.72-6.72,6.73,6.73,0,0,1-6.72,6.72M26.5,2a4.72,4.72,0,1,0,4.72,4.72A4.73,4.73,0,0,0,26.5,2"/>
                                                        <path d="M26.5,37.27a6.72,6.72,0,1,1,6.72-6.72,6.73,6.73,0,0,1-6.72,6.72m0-11.44a4.72,4.72,0,1,0,4.72,4.72,4.73,4.73,0,0,0-4.72-4.72"/>
                                                        <path d="M11.82,16.57a1,1,0,0,1-.52-1.86l9.8-5.87a1,1,0,0,1,1,1.72l-9.8,5.87a1,1,0,0,1-.51.14"/>
                                                        <path d="M21.62,28.56a1,1,0,0,1-.51-.14l-10-6a1,1,0,0,1,1-1.71l10,6a1,1,0,0,1-.51,1.86"/>
                                                    </svg>
                                                </i>
                                            </div>
                                            <div className="post__action-title">
                                                Share
                                            </div>
                                        </a>
                                        <a href="#" className="post__action">
                                            <div className="post__action-icon d-flex align-items-center justify-content-center">
                                                <i className="icon__download">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 37.08">
                                                        <path d="M34,37.08H1a1,1,0,0,1,0-2H34a1,1,0,0,1,0,2" transform="translate(0)"/>
                                                        <path d="M17.5,32.8h0a1,1,0,0,1-.81-.41L3.81,14.68a1,1,0,0,1,1.14-1.53L10.14,15V1a1,1,0,0,1,1-1H23.85a1,1,0,0,1,1,1V15L30,13.15a1,1,0,0,1,1.14,1.53L18.31,32.38a1,1,0,0,1-.81.41M7.31,16.09l10.19,14,10.19-14-3.51,1.22a1,1,0,0,1-1.33-.94V2H12.14V16.37a1,1,0,0,1-.42.82,1,1,0,0,1-.91.13Z"/>
                                                    </svg>
                                                </i>
                                            </div>
                                            <div className="post__action-title">
                                                Download
                                            </div>
                                        </a>
                                        <a href="#" className="post__action">
                                            <div className="post__action-icon d-flex align-items-center justify-content-center">
                                                <i className="icon__print">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.04 34.53">
                                                        <path d="M33,31.14H26.85a1,1,0,0,1-1-1V25.75H8.19v4.39a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V11.27a1,1,0,0,1,1-1H33a1,1,0,0,1,1,1V30.14a1,1,0,0,1-1,1m-5.19-2H32V12.27H2V29.14H6.19V24.75a1,1,0,0,1,1-1H26.85a1,1,0,0,1,1,1Z"/>
                                                        <path d="M25.21,11.77H8.84a.5.5,0,0,1-.5-.5V.5a.5.5,0,0,1,.5-.5H25.21a.5.5,0,0,1,.5.5V11.27a.5.5,0,0,1-.5.5m-15.87-1H24.71V1H9.34Z"/>
                                                        <path d="M24.19,34.53H9.85a.5.5,0,0,1-.5-.5v-6.7a.5.5,0,0,1,.5-.5H24.19a.5.5,0,0,1,.5.5V34a.5.5,0,0,1-.5.5m-13.84-1H23.69v-5.7H10.35Z"/>
                                                        <path d="M5,17.12A2.07,2.07,0,1,1,7,15.05,2.07,2.07,0,0,1,5,17.12M5,14A1.07,1.07,0,1,0,6,15.05,1.07,1.07,0,0,0,5,14"/>
                                                    </svg>
                                                </i>
                                            </div>
                                            <div className="post__action-title">
                                                Print
                                            </div>
                                        </a>
                                        <a href="#" className="news__it-social hidden-sm-up">
                                            f
                                        </a>
                                    </div>
                                    <div className="post__author hidden-sm-down">
                                        Рафаэль Абрамян и Максим Тадевосян будут развивать цифровые проекты для компаний реального сектор
                                    </div>
                                </div>
                                <div className="col col-lg-8 col-12">
                                    <div className="post__text">
                                        <h2>
                                            Журнал нанял внешних консультантов для повышения стоимости бизнеса
                                        </h2>
                                        <p>
                                            Как стало известно “Ъ”, журнал Forbes сменил часть управляющей команды и привлек в качестве консультантов выходцев из Rambler & Co Максима Тадевосяна и Рафаэля Абрамяна. Их бюро РАМТ обязалось увеличить стоимость бизнеса издания до конца 2023 года за счет запуска новых продуктов. Ряд источников “Ъ” утверждают, что консультанты даже получили фантомный опцион на долю в Forbes, однако владелец бизнеса Магомед Мусаев это отрицает.
                                        </p>
                                        <p>
                                            «АС Рус Медиа», издающая Forbes в России, наняла консалтинговое бюро РАМТ, рассказали “Ъ” источники в нескольких медиакомпаниях, включая сотрудников журнала. РАМТ разработало для Forbes стратегию и операционный план, подразумевающий изменения в структуре компании и управлении, уточняет один из собеседников. К работе с Forbes РАМТ, по информации собеседников “Ъ”, приступило в сентябре, вскоре после этого из издания ушли исполнительный директор Глеб Прозоров и финансовый директор Светлана Левицкая. На место господина Прозорова был назначен бывший топ-менеджер Rambler Денис Кошкин, который также выполняет функции финдиректора.
                                        </p>
                                        <div className="post__main">
                                            По словам источников “Ъ”, задача консультантов — повышение стоимости компании за счет новых сервисных продуктов, например подписных и образовательных, и рост операционной эффективности.
                                        </div>
                                        <p>
                                            Два источника утверждают, что РАМТ обязалось увеличить стоимость бизнеса к концу 2023 года, и за это консультанты получат фантомный опцион в размере 20% от разницы в капитализации компании на входе в проект, который состоялся в сентябре, и на выходе из него — 31 декабря 2023 года.
                                        </p>
                                        <p>
                                            Владелец Forbes в России Магомед Мусаев опроверг информацию про фантомный опцион, заявив “Ъ”, что компания только что утвердила новую стратегию и работает с разными консультантами, в их числе и члены совета директоров. «Как только я купил издание, каждый день консультируюсь с партнерами. Любой внешний игрок может прийти, и если он увеличит капитализацию Forbes, я найду механизм его мотивировать»,— добавил Магомед Мусаев. Все операционные решения принимает менеджмент во главе с гендиректором журнала Эльмаром Муртазаевым, он отвечает и за кадровую политику, подчеркнул господин Мусаев. «Консультации с РАМТ были, но они не носят характер отдельного большого соглашения»,— сообщил господин Муртазаев.
                                        </p>
                                        <blockquote>
                                            <p>
                                                Консалтинговое бюро РАМТ в начале 2021 года основали бывший гендиректор Rambler Group Рафаэль Абрамян и бывший гендиректор входившего в нее медиахолдинга Rambler & Co (объединяет Lenta.ru, Gazeta.ru, «Афиша» и другие медиа) Максим Тадевосян. Они ушли из корпорации после ее поглощения «Сбером» и занялись консультированием цифровых сервисов для разных компаний, разработкой стратегий, бизнес-планов и др.
                                            </p>
                                            <p>
                                                По данным Ассоциации коммуникационных агентств России, объем рекламы в журналах в первые три квартала 2021 года снизился на 2% по отношению к январю—сентябрю 2020-го, до 3,6 млрд руб.; падение к тому же периоду докризисного 2019 года составило 45%.
                                            </p>
                                        </blockquote>
                                        <p>
                                            Выручка «АС Медиа Рус» в 2020 году падала гораздо медленнее рынка, на 6%, до 550 млн руб., убыток составил 45 млн руб., по данным Kartoteka.ru. Бизнесмен Магомед Мусаев стал владельцем российского Forbes в 2018 году, выкупив его у Александра Федотова. Лицензия журнала была продлена до 2023 года, в том же году была принята трехлетняя стратегия журнала. Позже, в 2021 году, господин Мусаев приобрел лицензию на издание Harvard Business Review в России. Журнал Forbes покупался за долги и стал прибыльным по итогам первого полугодия 2021 года, говорит один из источников.
                                        </p>
                                        <p>
                                            Гендиректор холдинга «ИМ-Медиа» (в прошлом Bauer Media Group в России, выпускает «Тайны звезд», «Оракул» и др.) Алексей Иванов говорит, что в издательском масс-маркете консультанты обычно не привлекаются: «Как правило, мы ищем людей, которые могут расширить горизонты, и берем их в штат». Но Forbes — бизнес-глянец, и ситуация может отличаться, отмечает он. После проседания рекламного рынка в 2020 году точками роста таких изданий, по его мнению, может быть привлечение обратно рекламодателей и развитие направления мероприятий.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
                break;
        }

        if(window.matchMedia("(min-width: 1024px)").matches){
            return (
                <CustomScroll heightRelativeToParent="100%" allowOuterScroll="true">
                    {body}
                </CustomScroll>
            );
        }

        return  body;
    }

    render() {
        return (
            <>
                <Header page={'post'} ref={instance => { this.header = instance; }}/>
                <div className="content content--post">
                    <div className="container">
                        <div id="post" className="sc sc__box sc__post">
                            <div className={'post__scroll-box ' + ((this.state.full) ? 'active' : '')}>
                                <a href="/#news" className="post__close hidden-sm-down">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                        <path d="M0.293,1.707 L1.707,0.293 L29.707,28.293 L28.293,29.707 L0.293,1.707 Z"/>
                                        <path d="M28.293,0.293 L29.707,1.707 L1.707,29.707 L0.293,28.293 L28.293,0.293 Z"/>
                                    </svg>
                                </a>
                                {this.renderPostBody()}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>

        )
    }
}

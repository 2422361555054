import React from 'react'
import { Link } from 'react-router-dom';
import {isMobile, isIOS} from 'react-device-detect'
import ReactWOW from 'react-wow'

export default class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            nav: false,
        };
    }

    componentDidMount() {

    }

    goToAnchor = (anchor) => {
        let el = document.getElementById(anchor);
        el.scrollIntoView({
            behavior: 'smooth'
        })
    }

    renderAnchorLi = () => {
        if(this.props.page === 'home'){
            return(
                <>
                    <li className={(this.props.screen === 1) ? 'active' : ''}>
                        <a onClick={()=>{
                            this.props.goToAnchor('about')
                        }} className="d-flex align-items-center">
                            Главное
                        </a>
                    </li>
                    <li className={(this.props.screen === 4) ? 'active' : ''}>
                        <a onClick={()=>{
                            this.props.goToAnchor('principles')
                        }} className="d-flex align-items-center">
                            Решения
                        </a>
                    </li>
                    <li className={(this.props.screen === 5) ? 'active' : ''}>
                        <a onClick={()=>{
                            this.props.goToAnchor('industries')
                        }} className="d-flex align-items-center">
                            Индустрии
                        </a>
                    </li>
                    <li className={(this.props.screen === 6) ? 'active' : ''}>
                        <a onClick={()=>{
                            this.props.goToAnchor('challenges')
                        }} className="d-flex align-items-center">
                            Вызовы
                        </a>
                    </li>
                    <li className={(this.props.screen === 7) ? 'active' : ''}>
                        <a onClick={()=>{
                            this.props.goToAnchor('model')
                        }} className="d-flex align-items-center">
                            Бизнес-модель
                        </a>
                    </li>
                    <li className={(this.props.screen === 8 || this.props.screen === 9) ? 'active' : ''}>
                        <a onClick={()=>{
                            this.props.goToAnchor('team-1')
                        }} className="d-flex align-items-center">
                            Команда
                        </a>
                    </li>
                    {/*<li className={(this.props.screen === 11) ? 'active' : ''}>
                        <a onClick={()=>{
                            this.props.goToAnchor('news')
                        }} className="d-flex align-items-center">
                            Новости
                        </a>
                    </li>*/}
                    <li className={(this.props.screen === 10) ? 'active' : ''}>
                        <a onClick={()=>{
                            this.props.goToAnchor('contacts')
                        }} className="d-flex align-items-center">
                            Контакты
                        </a>
                    </li>
                </>
            )
        }else{
            return(
                <>
                    <li>
                        <a href="/#about" className="d-flex align-items-center">
                            Главное
                        </a>
                    </li>
                    <li>
                        <a href="/#principles" className="d-flex align-items-center">
                            Решения
                        </a>
                    </li>
                    <li>
                        <a href="/#industries" className="d-flex align-items-center">
                            Индустрии
                        </a>
                    </li>
                    <li>
                        <a href="/#challenges" className="d-flex align-items-center">
                            Вызовы
                        </a>
                    </li>
                    <li>
                        <a href="/#model" className="d-flex align-items-center">
                            Бизнес-модель
                        </a>
                    </li>
                    <li>
                        <a href="/#team-1" className="d-flex align-items-center">
                            Команда
                        </a>
                    </li>
                    <li>
                        <a href="/#new" className="d-flex align-items-center">
                            Новости
                        </a>
                    </li>
                    <li>
                        <a href="/#contacts" className="d-flex align-items-center">
                            Контакты
                        </a>
                    </li>
                </>
            )
        }
    }

    render() {
        return (
            <>
                <div className={'header ' + ((this.props.mode === 'small') ? 'header--small' : '')}>
                    <div className="hd__top">
                        <div className="container d-flex align-items-center justify-content-between">
                            <div className="hd__top-left d-flex align-items-center">
                                <div className="hd__top-decor hidden-sm-down"/>
                                <div className="hd__time hidden-sm-up">
                                    4:20
                                </div>
                                <div className="lang__list hidden-sm-down">
                                    <ul>
                                        <li>
                                            <a href="#">RU</a>
                                        </li>
                                        <li className="active">
                                            <a href="#">EN</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="hd__top-right d-flex align-items-center hidden-sm-down">
                                <div className="hd__social-label">
                                    Social networks:
                                </div>
                                <div className="hd__social-list">
                                    <ul>
                                        <li>
                                            <a href="#">Facebook</a>
                                        </li>
                                        <li>
                                            <a href="#">Twitter</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hd__lang hidden-sm-up">
                        <div className="container d-flex align-items-center">
                            <div className="lang__list">
                                <ul>
                                    <li>
                                        <a href="#">RU</a>
                                    </li>
                                    <li className="active">
                                        <a href="#">EN</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="hd__main">
                        <div className="container d-flex align-items-center justify-content-between">
                            <a href="/" className="hd__logo">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.08 99.54">
                                    <polygon points="108.84 0 132.16 58.06 95.28 58.06 91.95 66.36 135.5 66.36 148.82 99.54 158.29 99.54 118.3 0 108.84 0"/>
                                    <polygon points="282.02 0 282.02 8.31 331.79 8.31 331.79 99.54 340.08 99.54 340.08 0 282.02 0"/>
                                    <polygon points="245.25 0 210 87.76 174.75 0 165.28 0 205.27 99.54 205.27 99.54 214.73 99.54 214.73 99.54 248.84 14.62 248.84 99.54 257.14 99.54 257.14 0 245.25 0"/>
                                    <path d="M64.66,29C64.66,11.11,52.52,0,33,0H0V8.3H33.9c14,0,22.65,7.94,22.65,20.73,0,10.94-6.25,18.33-16.91,20.3a29.25,29.25,0,0,1-5.31.43H0V58H29.54L53.46,99.54h9.46L38.85,57.74C55.07,55.62,64.66,45,64.66,29"/>
                                </svg>
                            </a>
                            <div className="hd__nav">
                                <ul>
                                    {
                                        this.renderAnchorLi()
                                    }
                                </ul>
                            </div>
                            <div className="nav__bars hidden-sm-up">
                                <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="48" height="48" fill="white"/>
                                    <rect x="12" y="10.5" width="24" height="3" fill="#030405"/>
                                    <rect x="12" y="22.5" width="24" height="3" fill="#030405"/>
                                    <rect x="12" y="32.5" width="24" height="3" fill="#030405"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

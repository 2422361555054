import React from 'react'
import { Link} from 'react-router-dom'
import {Helmet} from "react-helmet"
import ReactWOW from 'react-wow';
import Slider from "react-slick"
import CustomScroll from 'react-custom-scroll';
import {isMobile, isIOS, isAndroid} from 'react-device-detect'
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from './Footer'
import Header from './Header'

gsap.registerPlugin(ScrollTrigger);

let sl_set = {};

export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            ch_cur: 0,
            full: false
        }
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);

        document.body.classList.add('page-not-front');
    }

    componentWillUnMount(){
        document.body.classList.remove('page-not-front');
    }

    goToAnchor = (anchor) => {
        let el = document.getElementById(anchor);
        el.scrollIntoView({
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <>
                <Header page={'subscribe'} ref={instance => { this.header = instance; }}/>
                <div className="content content--subscribe">
                    <div className="post__close hidden-sm-down">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                            <path d="M0.293,1.707 L1.707,0.293 L29.707,28.293 L28.293,29.707 L0.293,1.707 Z"/>
                            <path d="M28.293,0.293 L29.707,1.707 L1.707,29.707 L0.293,28.293 L28.293,0.293 Z"/>
                        </svg>
                    </div>
                    <div className="container">
                        <div className="subscribe__form-box">
                            <div className="row row__custom">
                                <div className="col col-lg-6 col-12">
                                    <div className="subscribe__icon-box d-flex align-items-center justify-content-center">
                                        <img src={require("../assets/img/subscribe-icon.svg").default}/>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="subscribe__form-header">
                                        <div className="subscribe__form-title">
                                            Subscribe
                                        </div>
                                        <div className="subscribe__form-subtitle">
                                            Get full access to articles and reports, and receive email newsletters and alerts that keep you in touch with our latest thinking. <br className="hidden-sm-up"/>(* Required fields)
                                        </div>
                                    </div>
                                    <div className="subscribe__form">
                                        <div className="row row__custom form__list">
                                            <div className="col col-lg-6 col-12 form__it">
                                                <div className="form__field">
                                                    <input type="text" placeholder="First name"/>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <div className="form__field">
                                                    <input type="text" placeholder="Last name"/>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <div className="form__field">
                                                    <input type="text" placeholder="Job title"/>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <div className="form__field">
                                                    <input type="text" placeholder="Organization"/>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <div className="form__field">
                                                    <input type="text" placeholder="Email"/>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <div className="form__field">
                                                    <input type="text" placeholder="Password"/>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <div className="checkbox__wrap">
                                                    <label>
                                                        <input type="checkbox"/>
                                                        <span className="checkbox__decor"/>
                                                        <span className="checkbox__title">
                                                        I agree to the processing of my personal data
                                                    </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col col-12"/>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <button type="submit" className="form__submit d-flex align-items-center justify-content-center">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>

        )
    }
}

import React from 'react'
import { Link } from 'react-router-dom';
import 'url-search-params-polyfill';

export default class Footer extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="footer">
                <div className="container">
                    <div className="row row__custom">
                        <div className="col col-lg-3 col-12 ft__col hidden-sm-down">
                            <div className="ft__logo">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.08 99.54">
                                    <polygon points="108.84 0 132.16 58.06 95.28 58.06 91.95 66.36 135.5 66.36 148.82 99.54 158.29 99.54 118.3 0 108.84 0"/>
                                    <polygon points="282.02 0 282.02 8.31 331.79 8.31 331.79 99.54 340.08 99.54 340.08 0 282.02 0"/>
                                    <polygon points="245.25 0 210 87.76 174.75 0 165.28 0 205.27 99.54 205.27 99.54 214.73 99.54 214.73 99.54 248.84 14.62 248.84 99.54 257.14 99.54 257.14 0 245.25 0"/>
                                    <path d="M64.66,29C64.66,11.11,52.52,0,33,0H0V8.3H33.9c14,0,22.65,7.94,22.65,20.73,0,10.94-6.25,18.33-16.91,20.3a29.25,29.25,0,0,1-5.31.43H0V58H29.54L53.46,99.54h9.46L38.85,57.74C55.07,55.62,64.66,45,64.66,29"/>
                                </svg>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-12 ft__col">
                            <div className="subscribe__box">
                                <div className="subscribe__box-title">
                                    Subscribe
                                </div>
                                <div className="subscribe__label">
                                    Select topics and stay current with our latest insights
                                </div>
                                <div className="form__field">
                                    <input type="text" placeholder="Email address"/>
                                </div>
                                <div className="d-flex">
                                    <button type="submit" onClick={(e)=>{
                                        this.props.showSbscrModal();
                                        e.preventDefault();
                                    }} className="form__submit d-flex align-items-center">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-6 col-12 ft__col">
                            <div className="d-flex justify-content-end">
                                <div className="ft__nav">
                                    <ul>
                                        <li>
                                            <Link to="/contacts">Contact us</Link>
                                        </li>
                                        <li>
                                            <a href="#">FAQ</a>
                                        </li>
                                        <li>
                                            <a href="#">Privacy policy</a>
                                        </li>
                                        <li>
                                            <a href="#">Cookie preferences</a>
                                        </li>
                                        <li>
                                            <a href="#">Terms of use</a>
                                        </li>
                                    </ul>
                                    <div className="ft__logo hidden-sm-up">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.08 99.54">
                                            <polygon points="108.84 0 132.16 58.06 95.28 58.06 91.95 66.36 135.5 66.36 148.82 99.54 158.29 99.54 118.3 0 108.84 0"/>
                                            <polygon points="282.02 0 282.02 8.31 331.79 8.31 331.79 99.54 340.08 99.54 340.08 0 282.02 0"/>
                                            <polygon points="245.25 0 210 87.76 174.75 0 165.28 0 205.27 99.54 205.27 99.54 214.73 99.54 214.73 99.54 248.84 14.62 248.84 99.54 257.14 99.54 257.14 0 245.25 0"/>
                                            <path d="M64.66,29C64.66,11.11,52.52,0,33,0H0V8.3H33.9c14,0,22.65,7.94,22.65,20.73,0,10.94-6.25,18.33-16.91,20.3a29.25,29.25,0,0,1-5.31.43H0V58H29.54L53.46,99.54h9.46L38.85,57.74C55.07,55.62,64.66,45,64.66,29"/>
                                        </svg>
                                    </div>
                                    <div className="ft__rights">
                                        © 2022 RAMT  Invest Engineering Fund
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

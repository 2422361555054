import React from 'react'
import { Link} from 'react-router-dom'
import {Helmet} from "react-helmet"
import ReactWOW from 'react-wow';
import Slider from "react-slick"
import {isMobile, isIOS, isAndroid} from 'react-device-detect'
import {gsap} from 'gsap';
import ReactFullpage from "@fullpage/react-fullpage";
import {Swipe, Position} from "react-swipe-component"
import disableScroll from 'disable-scroll';
import Footer from './Footer'
import Header from './Header'
import GoogleMapReact from "google-map-react";
import Modals from "./Modals";

let sl_set = {}, sl2_set = {}, sl3_set = {}, sl4_set = {}, sl5_set = {}, sl6_set = {};

let anchors = ["promo", "news", "about", "challenges", "mission", "activities", "team", "contacts", "footer"];
if(window.matchMedia("(max-width: 1023px)").matches){
    anchors = ["promo", "news", "about", "challenges", "mission", "activities", "target", "team", "contacts", "footer"/*, "google"*/];
}

let animAbout1, animAbout2, animAbout3;
let animMission, animChallenges1, animChallenges2, animAct1, animAct2, animMbAbout1, animMbAbout2, animMbAbout3;

const MapMarker = () => <div className="map__marker">
    <div className="map__marker-point"/>
    <div className="map__marker-balloon">
        <div className="map__marker-balloon-ct">
            <div className="map__marker-balloon-decor">

            </div>
            <div className="map__marker-address">
                123112, Moscow <br/>
                Presnenskaya emb., 6b2, <br/>
                Empire Tower, entrance 2
            </div>
            <div className="map__marker-hint">
                Entrance from Afimoll
            </div>
        </div>
        <a href="tel:+74959876524" className="map__btn d-flex align-items-center justify-content-between">
            <i className="icon-phone">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.32 38">
                    <path d="M27.69,38a9.8,9.8,0,0,1-2.94-.47A35.57,35.57,0,0,1,16,33.68a37.05,37.05,0,0,1-5.3-4.19,38.8,38.8,0,0,1-6.71-8.2,32.91,32.91,0,0,1-2.71-6A23.91,23.91,0,0,1,.17,11.67,10.37,10.37,0,0,1,.77,5.89a23.23,23.23,0,0,1,1-2.16A6.11,6.11,0,0,1,6.11.35L7.27.13,7.84,0a1,1,0,0,1,.43,0h.21a2,2,0,0,1,1.79,1l.4.67c.39.64.77,1.28,1.14,1.94s.68,1.24,1,1.86l.7,1.31c.08.15.17.28.27.42L14,7.6c.19.31.36.59.51.88a2.3,2.3,0,0,1-.33,2.75c-.5.53-1,1-1.55,1.49l-.52.48-.66.61c-.36.33-.72.66-1.07,1-.13.13-.11.17-.06.27.4.77.8,1.55,1.23,2.3a24.52,24.52,0,0,0,4.29,5.52,24.74,24.74,0,0,0,4.47,3.37c.68.41,1.38.79,2.07,1.17a.45.45,0,0,0,.21.07c.08,0,.2-.13.24-.17l.84-1,1-1.17c.47-.51,1-1.11,1.6-1.67A2.25,2.25,0,0,1,29,23.21l.49.27,1.32.78,3.12,1.83,1.61,1,.75.46a2.14,2.14,0,0,1,1.05,2,9.06,9.06,0,0,1-1.26,4.54,6.34,6.34,0,0,1-2.35,2.08,14.65,14.65,0,0,1-4.08,1.61,8.73,8.73,0,0,1-1.94.22M8,2l-.33.06-1.13.22a4.15,4.15,0,0,0-3,2.34,21.41,21.41,0,0,0-.9,2,8.28,8.28,0,0,0-.49,4.68,22.17,22.17,0,0,0,1,3.26,31.24,31.24,0,0,0,2.54,5.69A36.85,36.85,0,0,0,12,28a35.09,35.09,0,0,0,5,4,33.88,33.88,0,0,0,8.31,3.62,7.29,7.29,0,0,0,3.84.21,12.67,12.67,0,0,0,3.53-1.39A4.47,4.47,0,0,0,34.36,33a7.16,7.16,0,0,0,1-3.58c0-.16,0-.16-.1-.22l-.76-.47-1.58-1L29.78,26l-1.32-.77L28.08,25a.58.58,0,0,0-.24-.07s-.06,0-.13.07c-.53.51-1.05,1.08-1.5,1.57l-1,1.14-.86,1a2.28,2.28,0,0,1-1.73.83,2.42,2.42,0,0,1-1.17-.32C20.72,28.8,20,28.4,19.31,28a26.79,26.79,0,0,1-4.83-3.64,26.45,26.45,0,0,1-4.64-6C9.39,17.59,9,16.79,8.56,16A2.15,2.15,0,0,1,9,13.39c.37-.37.75-.72,1.14-1.07l.64-.59.53-.49c.51-.47,1-.92,1.46-1.4.06-.06.15-.15,0-.45s-.27-.48-.42-.72l-.2-.3a6.79,6.79,0,0,1-.37-.59L11,6.47c-.32-.61-.65-1.22-1-1.83S9.33,3.38,9,2.76l-.41-.68L8.36,2H8"/>
                    <path d="M23.35,19.91a1,1,0,0,1-.71-.3l-5-5.09a1,1,0,0,1,0-1.4l5.08-5.17L20.49,6.85A1,1,0,0,1,20.77,5L32.82,3a1,1,0,0,1,.86.28,1,1,0,0,1,.28.86l-1.9,12.26a1,1,0,0,1-.84.84,1,1,0,0,1-1-.56l-1.06-2.23-5.05,5.14a1,1,0,0,1-.71.3m-3.6-6.09,3.6,3.67L28.69,12a1,1,0,0,1,1.62.27l.28.59,1.19-7.69L24.19,6.44l.65.32a1,1,0,0,1,.27,1.6Z"/>
                </svg>
            </i>
            <span>+7 495 9876542</span>
        </a>
    </div>
</div>;

export default class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isReady: false,
            ch_cur: 0,
            about_anim: false,
            screen: 0,
            nav_sc: 1,
            ab_slide: 1,
            schema_slide: 1,
            nav1: null,
            nav2: null
        }

        this.showSbscrModal = this.showSbscrModal.bind(this);

        this.goToAnchor = this.goToAnchor.bind(this);
    }

    componentDidMount() {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        this.setState({
            nav1: this.slider4,
            nav2: this.slider5
        });

        window.scrollTo(0, 0);

        /*if(window.location.hash){
            if(window.location.hash === '#news'){
                this.goToAnchor('news');
            }

            if(window.location.hash === '#about'){
                this.goToAnchor('about');
            }

            if(window.location.hash === '#challenges'){
                this.goToAnchor('challenges');
            }

            if(window.location.hash === '#mission'){
                this.goToAnchor('mission');
            }

            if(window.location.hash === '#activities'){
                this.goToAnchor('activities');
            }

            window.history.pushState("", document.title, window.location.pathname + window.location.search);
        }*/

        if(window.matchMedia("(max-width: 1023px)").matches){
            /*Promo animations*/

            gsap.from("#pr-span-1", {
                x: -100,
                opacity: 0,
                duration: 0.6,
                delay: 0.15,
            });

            gsap.timeline().from("#pr-span-2", {
                x: -100,
                opacity: 0,
                duration: 0.3,
                delay: 0.75,
            }).to('#pr-span-decor-2', {
                width: 0,
                duration: 0.3,
                delay: 0.15,
            });

            gsap.from("#pr-span-3", {
                x: -100,
                opacity: 0,
                duration: 0.3,
                delay: 0.85,
            });

            gsap.from("#pr-span-4", {
                x: -100,
                opacity: 0,
                duration: 0.3,
                delay: 0.95,
            });

            gsap.from(".sc__promo-decor", {
                scale: 0,
                duration: 0.5,
                delay: 0.75,
            });

            gsap.timeline().to("#subtitle-line-1", {
                y: '5.6rem',
                duration: 0.8,
                delay: 1.25,
            }).to("#subtitle-line-1", {
                x: '-200%',
                opacity: 0,
                duration: 0.3,
                delay: 0.1,
            });

            gsap.from("#subtitle-1", {
                opacity: 0,
                duration: 0.3,
                delay: 2.1,
            });

            gsap.timeline().to("#subtitle-line-2", {
                y: '8.8rem',
                duration: 0.8,
                delay: 1.25,
            }).to("#subtitle-line-2", {
                x: '-200%',
                opacity: 0,
                duration: 0.3,
                delay: 0.2,
            });

            gsap.from("#subtitle-2", {
                opacity: 0,
                duration: 0.3,
                delay: 2.2,
            });

            gsap.timeline().to("#subtitle-line-3", {
                y: '12rem',
                duration: 0.8,
                delay: 1.25,
            }).to("#subtitle-line-3", {
                x: '-200%',
                opacity: 0,
                duration: 0.3,
                delay: 0.3,
            }).from("#scroll-down-promo", {
                y: '-10%',
                opacity: 0,
                duration: 0.3,
                delay: 0.15,
            });

            gsap.from("#subtitle-3", {
                opacity: 0,
                duration: 0.3,
                delay: 2.3,
            });

            /*End promo animations*/

            animMbAbout1 = gsap.timeline().from(".ab__it--1 .ab__it-title", {
                x: '-60%',
                opacity: 0,
                duration: 0.2,
                delay: 0.1
            }).from(".ab__it--1 .ab__it-line", {
                width: 0,
                duration: 0.2,
                delay: 0.15
            }).from(".ab__it--1 .ab__it-descr", {
                x: '-60%',
                opacity: 0,
                duration: 0.2,
                delay: 0.2
            });

            animMbAbout2 = gsap.timeline().from(".ab__it--2 .ab__it-title", {
                x: '-60%',
                opacity: 0,
                duration: 0.2,
                delay: 0.1
            }).from(".ab__it--2 .ab__it-line", {
                width: 0,
                duration: 0.2,
                delay: 0.15
            }).from(".ab__it--2 .ab__it-descr", {
                x: '-60%',
                opacity: 0,
                duration: 0.2,
                delay: 0.2
            });

            animMbAbout3 = gsap.timeline().from(".ab__it--3 .ab__it-title", {
                x: '-60%',
                opacity: 0,
                duration: 0.2,
                delay: 0.1
            }).from(".ab__it--3 .ab__it-line", {
                width: 0,
                duration: 0.2,
                delay: 0.15
            }).from(".ab__it--3 .ab__it-descr", {
                x: '-60%',
                opacity: 0,
                duration: 0.2,
                delay: 0.2
            });

            animMbAbout1.pause();
            animMbAbout2.pause();
            animMbAbout3.pause();

        }else{
            /*Promo animations*/

            gsap.from("#pr-span-1", {
                x: -100,
                opacity: 0,
                duration: 0.6,
                delay: 0.15,
            });

            gsap.from("#pr-span-2", {
                x: -100,
                opacity: 0,
                duration: 0.3,
                delay: 0.75,
            });

            gsap.from("#pr-span-3", {
                x: -100,
                opacity: 0,
                duration: 0.3,
                delay: 0.85,
            });

            gsap.from("#pr-span-4", {
                x: -100,
                opacity: 0,
                duration: 0.3,
                delay: 0.95,
            });

            gsap.from(".sc__promo-decor", {
                scale: 0,
                duration: 0.5,
                delay: 0.75,
            });

            gsap.timeline().to("#subtitle-line-1", {
                y: '7.5rem',
                duration: 0.8,
                delay: 1.25,
            }).to("#subtitle-line-1", {
                x: '100%',
                opacity: 0,
                duration: 0.3,
                delay: 0.1,
            });

            gsap.from("#subtitle-1", {
                opacity: 0,
                duration: 0.3,
                delay: 2.1,
            });

            gsap.timeline().to("#subtitle-line-2", {
                y: '12.25rem',
                duration: 0.8,
                delay: 1.25,
            }).to("#subtitle-line-2", {
                x: '100%',
                opacity: 0,
                duration: 0.3,
                delay: 0.2,
            });

            gsap.from("#subtitle-2", {
                opacity: 0,
                duration: 0.3,
                delay: 2.2,
            });

            gsap.timeline().to("#subtitle-line-3", {
                y: '17rem',
                duration: 0.8,
                delay: 1.25,
            }).to("#subtitle-line-3", {
                x: '100%',
                opacity: 0,
                duration: 0.3,
                delay: 0.3,
            }).from("#scroll-down-promo", {
                y: '-10%',
                opacity: 0,
                duration: 0.3,
                delay: 0.15,
            });

            gsap.from("#subtitle-3", {
                opacity: 0,
                duration: 0.3,
                delay: 2.3,
            });

            /*End promo animations*/


            animAbout1 = gsap.timeline().from(".ab__it--1 .ab__it-title", {
                x: '-60%',
                opacity: 0,
                duration: 0.4,
                delay: 0.15
            }).from(".ab__it--2 .ab__it-title", {
                x: '-60%',
                opacity: 0,
                duration: 0.4,
                delay: 0.85
            }).from(".ab__it--3 .ab__it-title", {
                x: '-60%',
                opacity: 0,
                duration: 0.4,
                delay: 1.45
            });

            animAbout1.pause();


            animAbout2 = gsap.timeline().from(".ab__it--1 .ab__it-line", {
                width: 0,
                duration: 0.4,
                delay: 0.3
            }).from(".ab__it--2 .ab__it-line", {
                width: 0,
                duration: 0.4,
                delay: 1
            }).from(".ab__it--3 .ab__it-line", {
                width: 0,
                duration: 0.4,
                delay: 1.6
            });

            animAbout2.pause();

            animAbout3 = gsap.timeline().from(".ab__it--1 .ab__it-descr", {
                x: '-60%',
                opacity: 0,
                duration: 0.4,
                delay: 0.45
            }).to(".ab__it--1", {
                y: '46.7rem',
                duration: 0.6,
                delay: 0.2
            }).from(".ab__it--2 .ab__it-descr", {
                x: '-60%',
                opacity: 0,
                duration: 0.4,
                delay: 0.4
            }).to(".ab__it--2", {
                y: '25rem',
                duration: 0.6,
                delay: 0.2
            }).from(".ab__it--3 .ab__it-descr", {
                x: '-60%',
                opacity: 0,
                duration: 0.4,
                delay: 0.5
            }).from(".ab__arrow-box", {
                x: '-100%',
                y: '100%',
                duration: 0.6,
                delay: 0
            });

            animAbout3.pause();

            /*Mission animations*/

            animMission = gsap.timeline().from(".sc__mission .sc__header", {
                scale: 0,
                duration: 0.6,
                delay: 0.3
            }).from(".sc__ct-box-wrap", {
                width: 0,
                duration: 0.7,
                delay: 0.1
            });

            animMission.pause();

            /*End mission animations*/

            /*Activities animations*/

            animAct1 = gsap.timeline().from(".schema__img", {
                opacity: 0,
                y: '4.6rem',
                duration: 0.6,
                delay: 0.2
            }).from(".schema__it", {
                y: '5rem',
                opacity: 0,
                duration: 0.4,
                delay: 0.15,
                stagger: {
                    each: 0.15
                }
            }).to(".schema__it--3", {
                opacity: 0,
                duration: 0.2,
                delay: 0.3
            }).from(".schema__img-over", {
                opacity: 0,
                duration: 0.3,
                delay: 0.1
            }).from(".schema__it-pin-top", {
                scale: 0,
                duration: 0.3,
                delay: 0.1
            }).from(".schema__decor-line-1", {
                top: '20rem',
                duration: 0.5,
                delay: 0
            }).from(".schema__decor-line-2", {
                width: 0,
                duration: 0.5,
                delay: 0
            }).from(".schema__decor-line-3", {
                height: 0,
                duration: 0.5,
                delay: 0
            }).from(".schema__top", {
                opacity: 0,
                y: '5rem',
                duration: 0.3,
                delay: 0.1
            }).from(".activities__it", {
                opacity: 0,
                x: '-10rem',
                duration: 0.3,
                delay: 0,
                stagger: {
                    each: 0.15
                }
            });

            animAct1.pause();

            animAct2 = gsap.timeline().from(".schema__target", {
                opacity: 0,
                x: '-10rem',
                duration: 0.6,
                delay: 0.25
            });

            animAct2.pause();

            /*End activities animations*/

            /*Challenges animations*/

            animChallenges1 = gsap.timeline().from(".ch__box-it", {
                scale: 0,
                duration: 0.5,
                delay: 0.1,
                stagger: {
                    each: 0.15
                }
            }).from(".active .ch__box-it-img-box", {
                height: 0,
                y: '3rem',
                duration: 0.5,
                delay: 0.1
            });

            animChallenges1.pause();

            animChallenges2 = gsap.timeline().from(".ch__box-right", {
                scale: 0.5,
                opacity: 0,
                duration: 0.5,
                delay: 0.2
            });
            animChallenges2.pause();

            /*End challenges animations*/
        }

    }

    componentWillMount(){
        sl_set = {
            infinite: false,
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            speed: 800,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        arrows: false,
                    }
                }
            ],
            beforeChange: (oldIndex, newIndex) => {
                this.setState({
                    ch_cur: newIndex
                });

                document.querySelectorAll('.ch__box-it-img-box')[0].removeAttribute("style");
            }
        }

        sl2_set = {
            infinite: true,
            dots: true,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800,
        }

        sl3_set = {
            infinite: true,
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800,
        }

        sl4_set = {
            infinite: true,
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 800,
        }

        sl5_set = {
            infinite: true,
            dots: false,
            arrows: false,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: 0,
            variableWidth: true
        }

        sl6_set = {
            infinite: true,
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            speed: 800,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        arrows: false,
                    }
                }
            ]
        }
    }

    goToAnchor = (anchor) => {
        this.setState({
            nav_sc: anchor
        }, ()=> {
            let el = document.getElementById('goToBtn');
            el.click();
        });

    }

    showSbscrModal = ()=> {
        this.modals.subscribeShow()
    }

    createMapOptions = (maps) => {
        return {
            panControl: false,
            scrollwheel: false,
            styles: [{ stylers:
                    [
                        {
                            "featureType": "water",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#d3d3d3"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "stylers": [
                                {
                                    "color": "#808080"
                                },
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#b3b3b3"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "weight": 1.8
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#d7d7d7"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ebebeb"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#a7a7a7"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#efefef"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#696969"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "color": "#737373"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "color": "#d6d6d6"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {},
                        {
                            "featureType": "poi",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#dadada"
                                }
                            ]
                        }
                    ]
            }]
        }
    }

    renderNewsBox = () => {
        if(window.matchMedia("(max-width: 1023px)").matches){
            return(
                <>
                    <div className="news__sl-box">
                        <Slider ref={slider2 => (this.slider2 = slider2)} {...sl2_set}>
                            <div className="news__it-slide news__it-slide--1">
                                <div className="news__it-slide-ct">
                                    <div className="news__it-date">
                                        30 April, 2022
                                    </div>
                                    <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="d-flex align-items-start justify-content-between">
                                        <div className="news__it-title">
                                            Ever-ready
                                            IT infrastructure
                                        </div>
                                        <div className="news__it-arrow-sm">
                                            <svg viewBox="0 0 80 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 2.99463L78.8591 2.99463" stroke="#FF1EFF" stroke-width="2"/>
                                                <path d="M80 2.99465L76.0071 0L76.0071 5.9893L80 2.99465Z" fill="#FF1EFF"/>
                                            </svg>
                                        </div>
                                    </Link>
                                    <div className="news__it-descr">
                                        That’s true whether your goal is unlocking business value and innovation for today
                                    </div>
                                </div>
                                <div className="news__it-img">
                                    <img src={require("../assets/img/n-img-sm-1.jpg")}/>
                                    <div className="news__it-swipe-info">
                                        &lt;&lt; swipe for more &gt;&gt;
                                    </div>
                                </div>
                                <div className="news__it-bottom d-flex align-items-center justify-content-between">
                                    <div className="news__it-likes d-flex align-items-start">
                                        <i className="icon__likes"/>
                                        <span className="news__it-likes-info">
                                            420
                                        </span>
                                    </div>
                                    <div className="news__it-comments">
                                        128 comments
                                    </div>
                                    <a href="#" className="news__it-social">
                                        f
                                    </a>
                                </div>
                            </div>
                            <div className="news__it-slide news__it-slide--2">
                                <div className="news__it-slide-ct" style={{backgroundImage: 'url(' + require("../assets/img/n-img-1.jpg") + ')'}}>
                                    <div className="news__it-date">
                                        30 April, 2022
                                    </div>
                                    <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="d-flex align-items-start justify-content-between">
                                        <div className="news__it-title">
                                            We help <br/>
                                            transform <br/>
                                            customer service <br/>
                                            to generate <br/>
                                            growth
                                        </div>
                                        <div className="news__it-arrow-sm">
                                            <svg viewBox="0 0 80 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 2.99463L78.8591 2.99463" stroke="#FF1EFF" stroke-width="2"/>
                                                <path d="M80 2.99465L76.0071 0L76.0071 5.9893L80 2.99465Z" fill="#FF1EFF"/>
                                            </svg>
                                        </div>
                                    </Link>
                                    <div className="news__it-swipe-info">
                                        &lt;&lt; swipe for more &gt;&gt;
                                    </div>
                                </div>
                                <div className="news__it-bottom d-flex align-items-center justify-content-between">
                                    <div className="news__it-likes d-flex align-items-start">
                                        <i className="icon__likes"/>
                                        <span className="news__it-likes-info">
                                            180
                                        </span>
                                    </div>
                                    <div className="news__it-comments">
                                        15 comments
                                    </div>
                                    <a href="#" className="news__it-social">
                                        f
                                    </a>
                                </div>
                            </div>
                            <div className="news__it-slide news__it-slide--3">
                                <div className="news__it-slide-ct">
                                    <div className="news__it-date">
                                        30 April, 2022
                                    </div>
                                    <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="d-flex align-items-start justify-content-between">
                                        <div className="news__it-title">
                                            How digital twins
                                            enable autonomous operations
                                        </div>
                                        <div className="news__it-arrow-sm">
                                            <svg viewBox="0 0 80 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 2.99463L78.8591 2.99463" stroke="#FF1EFF" stroke-width="2"/>
                                                <path d="M80 2.99465L76.0071 0L76.0071 5.9893L80 2.99465Z" fill="#FF1EFF"/>
                                            </svg>
                                        </div>
                                    </Link>
                                    <div className="news__it-descr">
                                        That’s true whether your goal is unlocking business value and innovation for today or preparing the organization for the Cloud Continuum tomorrow.
                                    </div>

                                    <div className="news__it-swipe-info">
                                        &lt;&lt; swipe for more &gt;&gt;
                                    </div>
                                </div>
                                <div className="news__it-bottom d-flex align-items-center justify-content-between">
                                    <div className="news__it-likes d-flex align-items-start">
                                        <i className="icon__likes"/>
                                        <span className="news__it-likes-info">
                                            420
                                        </span>
                                    </div>
                                    <div className="news__it-comments">
                                        128 comments
                                    </div>
                                    <a href="#" className="news__it-social">
                                        f
                                    </a>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </>
            )
        }else{
            return(
                <>
                    <div className="news__net">
                        <div className="news__it news__it--1">
                            <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="news__it-bl">
                                <div className="news__it-title">
                                    How digital twins enable autonomous operations
                                </div>
                                <div className="news__it-descr">
                                    That’s true whether your goal is unlocking business value and innovation for today or preparing the organization for the Cloud Continuum tomorrow.
                                </div>
                                <div className="news__it-arrow"/>
                            </Link>
                        </div>
                        <div className="news__it news__it--2">
                            <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="news__it-bl" style={{backgroundImage: 'url(' + require("../assets/img/n-img-1.jpg") + ')'}}>
                                <div className="news__it-title">
                                    We help
                                    transform
                                    customer service
                                    to generate
                                    growth
                                </div>
                                <div className="news__it-arrow"/>
                            </Link>
                        </div>
                        <div className="news__it news__it--3">
                            <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="news__it-bl" style={{backgroundImage: 'url(' + require("../assets/img/n-img-2.jpg") + ')'}}>
                                <div className="news__it-title">
                                    Ever-ready <br/>
                                    IT infrastructure
                                </div>
                                <div className="news__it-info d-flex align-items-center">
                                    <div className="news__it-likes d-flex align-items-start">
                                        <i className="icon__likes"/>
                                        <span className="news__it-likes-info">
                                            420
                                        </span>
                                    </div>
                                    <div className="news__it-comments">
                                        128 comments
                                    </div>
                                </div>
                                <div className="news__it-arrow"/>
                            </Link>
                            <a href="#" className="news__it-social">
                                f
                            </a>
                        </div>
                        <div className="news__it news__it--1 news__it--lt-bt">
                            <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="news__it-bl">
                                <div className="news__it-date">
                                    28 April, 2022
                                </div>
                                <div className="news__it-title">
                                    Ever-ready
                                    IT infrastructure
                                </div>
                                <div className="news__it-arrow"/>
                            </Link>
                            <span className="news__it-decor"/>
                        </div>
                        <div className="news__it news__it--4 news__it--rt-ct">
                            <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="news__it-bl" style={{backgroundImage: 'url(' + require("../assets/img/n-img-3.jpg") + ')'}}>
                                <div className="news__it-ct">
                                    <div className="news__it-title">
                                        Reinventing packaging
                                    </div>
                                    <div className="news__it-arrow"/>
                                </div>
                            </Link>
                        </div>
                        <div className="news__it news__it--1 news__it--rt-bt">
                            <Link to={{pathname: '/post', state: { prevPath: window.location.pathname }}} className="news__it-bl">
                                <div className="news__it-title">
                                    Ever-ready <br/>
                                    IT infrastructure
                                </div>
                                <div className="news__it-info d-flex align-items-center">
                                    <div className="news__it-likes d-flex align-items-start">
                                        <i className="icon__likes"/>
                                        <span className="news__it-likes-info">
                                            420
                                        </span>
                                    </div>
                                    <div className="news__it-comments">
                                        128 comments
                                    </div>
                                </div>
                                <div className="news__it-arrow"/>
                            </Link>
                            <a href="#" className="news__it-social">
                                f
                            </a>
                        </div>
                    </div>
                    <div className="load__more-wrap d-flex justify-content-center">
                        <div className="btn__custom d-flex align-items-center justify-content-center">
                            More news
                        </div>
                    </div>
                </>
            )
        }
    }

    onSwipeUpListener = () => {
        if(this.state.ab_slide < 3){
            this.setState({
                ab_slide: parseInt(this.state.ab_slide) + 1
            }, () => {
                gsap.to(".ab__arrow", {
                    x: (parseInt(this.state.ab_slide) - 1) * 13.5 + 'rem',
                    y: -(parseInt(this.state.ab_slide) - 1) * 13.5 + 'rem',
                    duration: 0.5,
                    delay: 0.3
                });
                if(this.state.ab_slide === 2){
                    animMbAbout2.play();
                }
                if(this.state.ab_slide === 3){
                    animMbAbout3.play();
                }
            });
        }else {
            let el1 = document.getElementById('enableScroll');
            el1.click();
            this.goToAnchor(3);
        }
    }

    onSwipeUpListener2 = () => {
        if(this.state.schema_slide < 3){
            this.setState({
                schema_slide: parseInt(this.state.schema_slide) + 1
            });
        }else {
            let el1 = document.getElementById('enableScroll');
            el1.click();
            this.goToAnchor(6);
        }
    }

    onSwipeDownListener = () => {
        if(this.state.ab_slide > 1){
            this.setState({
                ab_slide: parseInt(this.state.ab_slide) - 1
            }, () => {
                let shift = 0;
                if(this.state.ab_slide === 2){
                    shift = 13.5;
                }

                gsap.to(".ab__arrow", {
                    x: shift + 'rem',
                    y: -1 * shift + 'rem',
                    duration: 0.5,
                    delay: 0.3
                });
            });
        }else {
            let el1 = document.getElementById('enableScroll');
            el1.click();
            this.goToAnchor(2);
        }
    }

    onSwipeDownListener2 = () => {
        if(this.state.schema_slide > 1){
            this.setState({
                schema_slide: parseInt(this.state.schema_slide) - 1
            });
        }else {
            let el1 = document.getElementById('enableScroll');
            el1.click();
            this.goToAnchor(5);
        }
    }

    renderAbBox = () => {
        if(window.matchMedia("(max-width: 1023px)").matches){
            return(
                <>
                    <div className={'ab__it ab__it--1 fadeInUpDes animated ' + ((this.state.ab_slide === 1) ? 'active' : '')}>
                        <div className="ab__it-header">
                            <div className="ab__it-title">
                                Investment
                            </div>
                            <div className="ab__it-lines">
                                <div className="ab__it-line"/>
                            </div>
                        </div>
                        <div className="ab__it-descr">
                            <span>RAMT</span> is an investment design bureau that addresses issues from creating new capital value in classic industries to asset management in order to increase efficiency and investment
                            attractiveness.
                        </div>
                    </div>
                    <div className={'ab__it ab__it--2 fadeInUpDes animated ' + ((this.state.ab_slide === 2) ? 'active' : '')}>
                        <div className="ab__it-header">
                            <div className="ab__it-title">
                                Responsibility
                            </div>
                            <div className="ab__it-lines">
                                <div className="ab__it-line"/>
                                <div className="ab__it-line"/>
                            </div>
                        </div>
                        <div className="ab__it-descr">
                            The key difference from the classic investment funds and Big4 consulting companies is a great practical experience and responsibility for the business result due to full integration into the business project and remuneration for performance. From strategy to product creation
                        </div>
                    </div>
                    <div className={'ab__it ab__it--3 fadeInUpDes animated ' + ((this.state.ab_slide === 3) ? 'active' : '')}>
                        <div className="ab__it-header">
                            <div className="ab__it-title">
                                Partnership
                            </div>
                            <div className="ab__it-lines">
                                <div className="ab__it-line"/>
                                <div className="ab__it-line"/>
                                <div className="ab__it-line"/>
                            </div>
                        </div>

                        <div className="ab__it-descr">
                            <span>RAMT</span> is a partner for large offline businesses that allows you to improve financial performance and increase the company's capitalization by developing and implementing a digital strategy, combining offline competencies and assets with new technologies and changes. Focus on business results is directly related to the remuneration of the company.
                        </div>
                    </div>
                    <div className="ab__arrow"/>
                </>
            )
        }else{
            return(
                <>
                    <div className="ab__row">
                        <div className="ab__it ab__it--1">
                            <div className="ab__it-title">
                                Investment
                            </div>
                            <div className="ab__it-lines">
                                <div className="ab__it-line"/>
                            </div>
                            <div className="ab__it-descr">
                                <span>RAMT</span> is an investment design bureau that addresses issues from creating new capital value in classic industries to asset management in order to increase efficiency and investment
                                attractiveness.
                            </div>
                        </div>
                        <div className="ab__it ab__it--2">
                            <div className="ab__it-title">
                                Responsibility
                            </div>
                            <div className="ab__it-lines">
                                <div className="ab__it-line"/>
                                <div className="ab__it-line"/>
                            </div>
                            <div className="ab__it-descr">
                                The key difference from the classic investment funds and Big4 consulting companies is a great practical experience and responsibility for the business result due to full integration into the business project and remuneration for performance. From strategy to product creation
                            </div>
                        </div>
                        <div className="ab__it ab__it--3">
                            <div className="ab__it-title">
                                Partnership
                            </div>
                            <div className="ab__it-lines">
                                <div className="ab__it-line"/>
                                <div className="ab__it-line"/>
                                <div className="ab__it-line"/>
                            </div>
                            <div className="ab__it-descr">
                                <span>RAMT</span> is a partner for large offline businesses that allows you to improve financial performance and increase the company's capitalization by developing and implementing a digital strategy, combining offline competencies and assets with new technologies and changes. Focus on business results is directly related to the remuneration of the company.
                            </div>
                        </div>
                    </div>
                    <div className="ab__arrow-box">
                        <div className="ab__arrow"/>
                    </div>
                </>
            )
        }
    }

    renderSchema = () => {
        if(window.matchMedia("(max-width: 1023px)").matches){
            return(
                <div className="schema__box">
                    <div className="schema__box-wrap">
                        <div className={'schema__box-top fadeInUpDes animated ' + (this.state.schema_slide === 1 ? 'active' : '')}>
                            <div className="schema__box-top-title">
                                Investment activity (M&A)
                            </div>
                        </div>
                        <div className={'schema__box-top fadeInUpDes animated ' + (this.state.schema_slide === 2 ? 'active' : '')}>
                            <div className="schema__box-top-title">
                                Transformation and creation of new capital value (project base, M&A)
                            </div>
                        </div>
                        <div className={'schema__box-top fadeInUpDes animated ' + (this.state.schema_slide === 3 ? 'active' : '')}>
                            <div className="schema__box-top-title">
                                Management company (JV / option)
                            </div>
                        </div>
                        <div className="schema__img">
                            <img src={require("../assets/img/schema-mb.svg").default} />

                            <div className={'schema__active schema__active--1 fadeIn animated ' + ((this.state.schema_slide === 1) ? 'active' : '')}>
                                <img src={require("../assets/img/sch_act-1.svg").default} />
                            </div>
                            <div className={'schema__active schema__active--2 fadeIn animated ' + ((this.state.schema_slide === 2) ? 'active' : '')}>
                                <img src={require("../assets/img/sch_act-2.svg").default} />
                            </div>
                            <div className={'schema__active schema__active--3 fadeIn animated ' + ((this.state.schema_slide === 3) ? 'active' : '')}>
                                <img src={require("../assets/img/sch_act-3.svg").default} />
                            </div>

                            <div className={'schema__it-mb schema__it-mb-1 ' + ((this.state.schema_slide === 1) ? 'active' : '')}>
                                <div className="schema__it-mb-title fadeInUpDes animated">
                                    Investment activity <br/>
                                    (M&A)
                                </div>
                                <div className="schema__it-mb-pin"></div>
                            </div>

                            <div className={'schema__it-mb schema__it-mb-2 fadeInUpDes animated ' + ((this.state.schema_slide === 2) ? 'active' : '')}>
                                <div className="schema__it-mb-title fadeInUpDes animated">
                                    Transformation and creation of new capital value (project base, M&A)
                                </div>
                                <div className="schema__it-mb-pin"></div>
                            </div>

                            <div className={'schema__it-mb schema__it-mb-3 fadeInUpDes animated ' + ((this.state.schema_slide === 3) ? 'active' : '')}>
                                <div className="schema__it-mb-title fadeInUpDes animated">
                                    Management company <br/>
                                    (JV / option)
                                </div>
                                <div className="schema__it-mb-pin"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return(
                <div className="schema__box">
                    <div className="schema__img">
                        <img src={require("../assets/img/schema.png")} />
                        <div className="schema__img-over">
                            <img src={require("../assets/img/schema-over.png")} />
                        </div>
                    </div>
                    <div className="schema__it schema__it--1">
                        <div className="schema__it-title">
                            Investment activity <br/>
                            (M&A)
                        </div>
                        <div className="schema__it-pin"/>
                    </div>
                    <div className="schema__it schema__it--2">
                        <div className="schema__it-title">
                            Transformation <br/>
                            and creation of new capital <br/>
                            value (project base, M&A)
                        </div>
                        <div className="schema__it-pin"/>
                    </div>
                    <div className="schema__it schema__it--3">
                        <div className="schema__it-title">
                            Management <br/>
                            company <br/>
                            (JV / option)
                        </div>
                        <div className="schema__it-pin"/>
                    </div>
                    <div className="schema__top">
                        Management <br/>
                        company <br/>
                        (JV / option)
                    </div>
                    <div className="schema__it-pin-top"/>
                    <div className="schema__decor">
                        <div className="schema__decor-line-1"/>
                        <div className="schema__decor-line-2"/>
                        <div className="schema__decor-line-3"/>
                    </div>
                    <div className="schema__target">
                        <div className="schema__target-title">
                            Target
                        </div>
                        <div className="schema__target-descr">
                            Increasing the competitiveness, efficiency and capital value of offline industries / companies through the implementation of an investment strategy in digital projects through M&A and the involvement of technology teams
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderTargetSection = () => {
        if(window.matchMedia("(max-width: 1023px)").matches){
            return(
                <>
                    <div className="section sc__target fp-auto-height">
                        <div className="schema__target">
                            <div className="schema__target-title">
                                Target
                            </div>
                            <div className="schema__target-descr">
                                Increasing the competitiveness, efficiency and capital value of offline industries / companies through the implementation of an investment strategy in digital projects through M&A and the involvement of technology teams
                            </div>
                        </div>
                        <div className="activities__list">
                            <div className="activities__it">
                                <div className="d-flex">
                                    <div className="activities__it-numb">
                                        1
                                    </div>
                                </div>
                                <div className="activities__it-descr">
                                    Development of a technology investment strategy, M&A and acceleration of young teams in technology industries relevant to the offline partner's core business
                                </div>
                            </div>
                            <div className="activities__it">
                                <div className="d-flex">
                                    <div className="activities__it-numb">
                                        2
                                    </div>
                                </div>
                                <div className="activities__it-descr">
                                    Management company for the assets of non-core investors in the field of digital projects. Increase in capital cost, increase business efficiency
                                </div>
                            </div>
                            <div className="activities__it">
                                <div className="d-flex">
                                    <div className="activities__it-numb">
                                        3
                                    </div>
                                </div>
                                <div className="activities__it-descr">
                                    Development of current portfolio assets for M&A purposes
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    renderContactsSection = () => {
        if(window.matchMedia("(max-width: 1023px)").matches){
            return(
                <>
                    <div className="section sc__contacts">
                        <div className="d-flex section__scroll-box">
                            <div className="scroll__down d-flex align-items-end">
                                <div className="scroll__down-arrow">

                                </div>
                                <div className="scroll__down-title">
                                    Contacts
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="subscribe__form-box">
                                <div className="row row__custom">

                                    <div className="col col-lg-6 col-12">
                                        <div className="subscribe__form-header">
                                            <div className="subscribe__form-title">
                                                Contact Us
                                            </div>
                                            <div className="subscribe__form-subtitle">
                                                Thank you for your interest in RAMT. Please fill out the form below to ask a question or report a technical problem.
                                            </div>
                                        </div>
                                        <div className="subscribe__form">
                                            <div className="row row__custom form__list">
                                                <div className="col col-lg-6 col-12 form__col">
                                                    <div className="form__it">
                                                        <div className="form__field">
                                                            <input type="text" placeholder="Name*"/>
                                                        </div>
                                                    </div>
                                                    <div className="form__it">
                                                        <div className="form__field">
                                                            <input type="text" placeholder="Email*"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 form__col">
                                                    <div className="form__field">
                                                        <textarea placeholder="Message*"></textarea>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 form__it">
                                                    <div className="checkbox__wrap">
                                                        <label>
                                                            <input type="checkbox"/>
                                                            <span className="checkbox__decor"/>
                                                            <span className="checkbox__title">
                                                                I agree to the processing of my personal data
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 form__col">
                                                    <div className="form__hint">
                                                        Required fields are marked with an asterisk (*)
                                                    </div>
                                                </div>
                                                <div className="col col-lg-6 col-12 form__it">
                                                    <button type="submit" className="form__submit d-flex align-items-center justify-content-center">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="section sc__map">
                        <div className="map">
                            <div style={{ height: '100%', width: '100%' }}>
                                <GoogleMapReact
                                    options={this.createMapOptions}
                                    defaultCenter={{ lat: 59.953413, lng: 30.342 }}
                                    defaultZoom={15}
                                >
                                    <MapMarker
                                        lat={59.955413}
                                        lng={30.337844}
                                    />
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>*/}
                </>
            )
        }else{
            return(
                <div className="section sc__contacts">
                    <div className="d-flex section__scroll-box">
                        <div className="scroll__down d-flex align-items-end">
                            <div className="scroll__down-arrow">

                            </div>
                            <div className="scroll__down-title">
                                Contacts
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="subscribe__form-box">
                            <div className="row row__custom">

                                <div className="col col-lg-6 col-12">
                                    <div className="subscribe__form-header">
                                        <div className="subscribe__form-title">
                                            Contact Us
                                        </div>
                                        <div className="subscribe__form-subtitle">
                                            Thank you for your interest in RAMT. Please fill out the form below to ask a question or report a technical problem.
                                        </div>
                                    </div>
                                    <div className="subscribe__form">
                                        <div className="row row__custom form__list">
                                            <div className="col col-lg-6 col-12 form__col">
                                                <div className="form__it">
                                                    <div className="form__field">
                                                        <input type="text" placeholder="Name*"/>
                                                    </div>
                                                </div>
                                                <div className="form__it">
                                                    <div className="form__field">
                                                        <input type="text" placeholder="Email*"/>
                                                    </div>
                                                </div>
                                                <div className="form__it">
                                                    <div className="form__field">
                                                        <input type="text" placeholder="Company or school*"/>
                                                    </div>
                                                </div>
                                                <div className="form__it">
                                                    <div className="form__field">
                                                        <input type="text" placeholder="Subject*"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__col">
                                                <div className="form__field">
                                                    <textarea placeholder="Message*"></textarea>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <div className="checkbox__wrap">
                                                    <label>
                                                        <input type="checkbox"/>
                                                        <span className="checkbox__decor"/>
                                                        <span className="checkbox__title">
                                                                            I agree to the processing of my personal data
                                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__col">
                                                <div className="form__hint">
                                                    Required fields are marked with an asterisk (*)
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12 form__it">
                                                <button type="submit" className="form__submit d-flex align-items-center justify-content-center">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="map">
                                        <div style={{ height: '100%', width: '100%' }}>
                                            <GoogleMapReact
                                                options={this.createMapOptions}
                                                defaultCenter={{ lat: 59.953413, lng: 30.342 }}
                                                defaultZoom={15}
                                            >
                                                <MapMarker
                                                    lat={59.955413}
                                                    lng={30.337844}
                                                />
                                            </GoogleMapReact>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderChallengesMobile = () => {
        if(window.matchMedia("(max-width: 1023px)").matches){
            return(
                <div className="ch__mobile-box">
                    <Slider asNavFor={this.state.nav2} ref={slider4 => (this.slider4 = slider4)} {...sl4_set}>
                        <div className="ch__mobile-sl">
                            <div className="ch__mobile-sl-img">
                                <img src={require("../assets/img/ch-mb-1.jpg")}/>
                                <div className="ch__mobile-sl-numb">
                                    <img src={require("../assets/img/numb-1.svg").default}/>
                                </div>
                            </div>
                            <div className="ch__mobile-sl-ct">
                                <div className="ch__ct-it-title">
                                    Challenges
                                </div>
                                <div className="sc__txt">
                                    92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                </div>
                            </div>
                        </div>
                        <div className="ch__mobile-sl">
                            <div className="ch__mobile-sl-img">
                                <img src={require("../assets/img/ch-mb-1.jpg")}/>
                                <div className="ch__mobile-sl-numb">
                                    <img src={require("../assets/img/numb-2.svg").default}/>
                                </div>
                            </div>
                            <div className="ch__mobile-sl-ct">
                                <div className="ch__ct-it-title">
                                    How so
                                </div>
                                <div className="sc__txt">
                                    We are not limited to the territory of the country, we believe that the best solutions created for our partners can and should be    cross-border and exportable.
                                </div>
                            </div>
                        </div>
                        <div className="ch__mobile-sl">
                            <div className="ch__mobile-sl-img">
                                <img src={require("../assets/img/ch-mb-1.jpg")}/>
                                <div className="ch__mobile-sl-numb">
                                    <img src={require("../assets/img/numb-1.svg").default}/>
                                </div>
                            </div>
                            <div className="ch__mobile-sl-ct">
                                <div className="ch__ct-it-title">
                                    And then
                                </div>
                                <div className="sc__txt">
                                    We grow offline business value by creating new capital value through increased operational efficiency and creating new monetization models
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div className="ch__mobile-decor-box">
                        <Slider asNavFor={this.state.nav1} ref={slider5 => (this.slider5 = slider5)} {...sl5_set}>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ch__mobile-decor">
                                <div className="ch__box-it">
                                    <div className="ch__box-it-sl">
                                        <div className="ch__box-it-sl-handle">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <>
                <Header page={'home'} goToAnchor={this.goToAnchor} screen={this.state.screen} mode={(this.state.screen > 0) ? 'small' : 'common'} ref={instance => { this.header = instance; }}/>
                <ReactFullpage
                    anchors={anchors}
                    navigationTooltips={anchors}
                    onLeave={(origin, destination, direction) => {
                        this.setState({
                            changing: true,
                            screen: destination.index
                        });

                        if(window.matchMedia("(max-width: 1023px)").matches){
                            if(destination.index === 2 && this.state.ab_slide === 1){
                                let el = document.getElementById('disableScroll');
                                el.click();
                            }

                            if(destination.index === 2 && this.state.ab_slide === 3){
                                let el = document.getElementById('disableScroll');
                                el.click();
                            }

                            if(destination.index === 5 && this.state.schema_slide === 1){
                                let el = document.getElementById('disableScroll');
                                el.click();
                            }

                            if(destination.index === 5 && this.state.schema_slide === 3){
                                let el = document.getElementById('disableScroll');
                                el.click();
                            }
                        }
                    }}

                    afterLoad={(origin, destination)=>{
                        this.setState({
                            changing: false,
                        });

                        if(window.matchMedia("(max-width: 1023px)").matches){
                            switch(destination.index){
                                case 2:
                                    animMbAbout1.play();
                                    break;
                            }
                        }else{
                            switch (destination.index){
                                case 1:
                                    gsap.to(".news__it", {
                                        y: 0,
                                        opacity: 1,
                                        duration: 0.4,
                                        delay: 0.15,
                                        stagger: {
                                            each: 0.15
                                        }
                                    });

                                    gsap.to(".load__more-wrap", {
                                        y: 0,
                                        opacity: 1,
                                        duration: 0.4,
                                        delay: 0.15
                                    });
                                    break;

                                case 2:
                                    animAbout1.play();
                                    animAbout2.play();
                                    animAbout3.play();
                                    break;

                                case 3:
                                    animChallenges1.play();
                                    animChallenges2.play();
                                    break;

                                case 4:
                                    animMission.play();
                                    break;

                                case 5:
                                    animAct1.play();
                                    animAct2.play();
                                    break;
                            }
                        }
                    }}
                    render={({ state, fullpageApi }) => {
                        return (
                            <>
                                <div className="section sc sc__box sc__promo">
                                    <div className="container">
                                        <div className="sc__promo-title">
                                            <span id="pr-span-1">Transform</span>
                                            <span id="pr-span-2">
                                        offline
                                        <span id="pr-span-decor-2"/>
                                    </span>
                                            &nbsp;
                                            <br className="hidden-sm-up"/>
                                            <span id="pr-span-3">
                                        through
                                        <span id="pr-span-decor-3"/>
                                    </span>
                                            &nbsp;
                                            <span id="pr-span-4">
                                        online
                                        <span id="subtitle-line-1" className="subtitle-line"/>
                                        <span id="subtitle-line-2" className="subtitle-line"/>
                                        <span id="subtitle-line-3" className="subtitle-line"/>
                                        <span id="pr-span-decor-4"/>
                                    </span>
                                        </div>
                                        <div className="sc__promo-bottom d-flex align-items-start justify-content-between">
                                            <div className="sc__promo-decor" aria-hidden="true"/>
                                            <div className="sc__promo-subtitle">
                                                <span id="subtitle-1">via investments basing on experience</span>
                                                <span id="subtitle-2">and opportunities to gain</span>
                                                <span id="subtitle-3">synergies</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="hidden">
                                        <button id="goToBtn" onClick={()=>{
                                            fullpageApi.moveTo(this.state.nav_sc);
                                        }}/>
                                        <button id="disableScroll" onClick={()=>{
                                            fullpageApi.setAllowScrolling(false);
                                        }}/>
                                        <button id="enableScroll" onClick={()=>{
                                            fullpageApi.setAllowScrolling(true);
                                        }}/>
                                        <button id="moveScDown" onClick={()=>{
                                            fullpageApi.moveSectionDown();
                                        }}/>
                                        <button id="moveScUp" onClick={()=>{
                                            fullpageApi.moveSectionUp();
                                        }}/>
                                    </div>
                                </div>
                                <div className="section sc sc__box sc__news">
                                    <div className="d-flex section__scroll-box">
                                        <div className="scroll__down d-flex align-items-end">
                                            <div className="scroll__down-arrow">

                                            </div>
                                            <div className="scroll__down-title">
                                                News
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        {this.renderNewsBox()}
                                    </div>

                                </div>
                                <div className="section sc sc__box sc__about">
                                    <div className="d-flex section__scroll-box">
                                        <div className="scroll__down d-flex align-items-end">
                                            <div className="scroll__down-arrow">

                                            </div>
                                            <div className="scroll__down-title">
                                                About us
                                            </div>
                                        </div>
                                    </div>
                                    <Swipe detectTouch={true} onSwipedUp={this.onSwipeUpListener} onSwipedDown={this.onSwipeDownListener}>
                                        <div className="container">
                                            <div className="ab__box" data-slide={this.state.ab_slide}>
                                                {this.renderAbBox()}
                                            </div>
                                        </div>
                                    </Swipe>
                                </div>
                                <div className="section sc sc__box sc__challenges">
                                    <div className="d-flex section__scroll-box">
                                        <div className="scroll__down d-flex align-items-end">
                                            <div className="scroll__down-arrow">

                                            </div>
                                            <div className="scroll__down-title">
                                                Challenges
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="ch__ct-box d-flex align-items-center hidden-sm-down">
                                            <div className="ch__box-left d-flex justify-content-between">
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 0) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(0)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 1) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(1)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 2) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(2)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 3) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(3)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 4) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(4)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 5) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(5)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 6) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(6)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 7) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(7)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 8) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(8)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 9) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(9)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 10) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(10)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 11) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(11)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 12) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(12)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 13) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(13)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 14) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(14)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={'ch__box-it ' + ((this.state.ch_cur === 15) ? 'active' : '')}>
                                                    <div className="ch__box-it-img-box">
                                                        <div className="ch__box-it-img">
                                                            <img src={require("../assets/img/ch-img-1.jpg")}/>
                                                        </div>
                                                    </div>
                                                    <div className="ch__box-it-sl">
                                                        <div onClick={()=>{
                                                            this.slider1.slickGoTo(15)
                                                        }} className="ch__box-it-sl-handle">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ch__box-right">
                                                <div onClick={()=>{
                                                    this.slider1.slickPrev();
                                                }} className="ch__box-nav ch__box-nav--prev"></div>
                                                <div onClick={()=>{
                                                    this.slider1.slickNext();
                                                }} className="ch__box-nav ch__box-nav--next"></div>
                                                <div className="ch__ct-sl">
                                                    <Slider ref={slider1 => (this.slider1 = slider1)} {...sl_set}>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                        <div className="ch__ct-it">
                                                            <div className="ch__ct-it-numb">
                                                                <img src={require("../assets/img/numb-1.png")}/>
                                                            </div>
                                                            <div className="ch__ct-it-title">
                                                                Challenges
                                                            </div>
                                                            <div className="sc__txt">
                                                                92/8 - the basis of the economy - offline industries and only <span>8%</span> of digital business, which puts pressure on offline
                                                            </div>
                                                        </div>
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sl__pager d-flex justify-content-center hidden-sm-down">
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(0)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 0) ? 'active' : '')}>
                                                01
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(1)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 1) ? 'active' : '')}>
                                                02
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(2)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 2) ? 'active' : '')}>
                                                03
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(3)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 3) ? 'active' : '')}>
                                                04
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(4)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 4) ? 'active' : '')}>
                                                05
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(5)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 5) ? 'active' : '')}>
                                                06
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(6)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 6) ? 'active' : '')}>
                                                07
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(7)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 7) ? 'active' : '')}>
                                                08
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(8)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 8) ? 'active' : '')}>
                                                09
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(9)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 9) ? 'active' : '')}>
                                                10
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(10)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 10) ? 'active' : '')}>
                                                11
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(11)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 11) ? 'active' : '')}>
                                                12
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(12)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 12) ? 'active' : '')}>
                                                13
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(13)
                                            }} className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 13) ? 'active' : '')}>
                                                14
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(14)
                                            }}  className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 14) ? 'active' : '')}>
                                                15
                                            </div>
                                            <div onClick={()=>{
                                                this.slider1.slickGoTo(15)
                                            }}  className={'sl__pager-it d-flex align-items-center justify-content-center ' + ((this.state.ch_cur === 15) ? 'active' : '')}>
                                                16
                                            </div>
                                        </div>

                                        {this.renderChallengesMobile()}
                                    </div>
                                </div>
                                <div className="section sc__miss-wrap">
                                    <div className="d-flex section__scroll-box">
                                        <div className="scroll__down d-flex align-items-end">
                                            <div className="scroll__down-arrow">

                                            </div>
                                            <div className="scroll__down-title">
                                                Mission
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="sc__mission" style={{backgroundImage: 'url(' + require("../assets/img/mission-bg.jpg") + ')'}}>
                                            <div className="sc__header d-flex justify-content-center">
                                                <div className="sc__title">
                                                    Mission
                                                    <div className="sc__promo-decor" aria-hidden="true"/>
                                                </div>
                                            </div>
                                            <div className="sc__ct-box">
                                                <div className="sc__ct-box-wrap">
                                                    <div className="sc__ct">
                                                        <div className="sc__txt">
                                                            We grow offline business value by creating new capital value through increased operational efficiency and creating new monetization models through digital products that are created by the best teams in Russia and the CIS. We are not limited to the territory of the country, we believe that the best solutions created for our partners can and should be <span>cross-border and exportable.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="section sc sc__box sc__activities">
                                    <div className="d-flex section__scroll-box">
                                        <div className="scroll__down d-flex align-items-end">
                                            <div className="scroll__down-arrow">

                                            </div>
                                            <div className="scroll__down-title">
                                                Activities
                                            </div>
                                        </div>
                                    </div>
                                    <Swipe detectTouch={true} onSwipedUp={this.onSwipeUpListener2} onSwipedDown={this.onSwipeDownListener2}>
                                        <div className="container">
                                            {this.renderSchema()}
                                            <div className="activities__list d-flex hidden-sm-down">
                                                <div className="activities__it">
                                                    <div className="d-flex">
                                                        <div className="activities__it-numb">
                                                            1
                                                        </div>
                                                    </div>
                                                    <div className="activities__it-descr">
                                                        Development of a technology investment strategy, M&A and acceleration of young teams in technology industries relevant to the offline partner's core business
                                                    </div>
                                                </div>
                                                <div className="activities__it">
                                                    <div className="d-flex">
                                                        <div className="activities__it-numb">
                                                            2
                                                        </div>
                                                    </div>
                                                    <div className="activities__it-descr">
                                                        Management company for the assets of non-core investors in the field of digital projects. Increase in capital cost, increase business efficiency
                                                    </div>
                                                </div>
                                                <div className="activities__it">
                                                    <div className="d-flex">
                                                        <div className="activities__it-numb">
                                                            3
                                                        </div>
                                                    </div>
                                                    <div className="activities__it-descr">
                                                        Development of current portfolio assets for M&A purposes
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Swipe>
                                </div>
                                {this.renderTargetSection()}
                                <div className="section sc__team">
                                    <div className="d-flex section__scroll-box">
                                        <div className="scroll__down d-flex align-items-end">
                                            <div className="scroll__down-arrow">

                                            </div>
                                            <div className="scroll__down-title">
                                                Team
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container">
                                        <div className="team__box">
                                            <div onClick={()=>{
                                                this.slider6.slickPrev();
                                            }} className="sl__nav sl__nav-prev"/>
                                            <div onClick={()=>{
                                                this.slider6.slickNext();
                                            }} className="sl__nav sl__nav-next"/>
                                            <div className="team__box-row">
                                                <Slider ref={slider => (this.slider6 = slider)} {...sl6_set}>
                                                    <div className="team__it">
                                                        <div className="team__it-bl">
                                                            <img src={require("../assets/img/team-1.jpg")}/>
                                                            <div className="team__it-ct d-flex align-items-center">
                                                                <div className="team__it-ct-bl">
                                                                    <div className="team__it-name">
                                                                        Rafael <br/>
                                                                        Abramyan
                                                                    </div>
                                                                    <div className="team__it-position">
                                                                        Cofounder
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team__it">
                                                        <div className="team__it-bl">
                                                            <img src={require("../assets/img/team-2.jpg")}/>
                                                            <div className="team__it-ct d-flex align-items-center">
                                                                <div className="team__it-ct-bl">
                                                                    <div className="team__it-name">
                                                                        Max <br/>
                                                                        Tadevosyan
                                                                    </div>
                                                                    <div className="team__it-position">
                                                                        Cofounder
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team__it">
                                                        <div className="team__it-bl">
                                                            <img src={require("../assets/img/team-1.jpg")}/>
                                                            <div className="team__it-ct d-flex align-items-center">
                                                                <div className="team__it-ct-bl">
                                                                    <div className="team__it-name">
                                                                        Rafael <br/>
                                                                        Abramyan
                                                                    </div>
                                                                    <div className="team__it-position">
                                                                        Cofounder
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team__it">
                                                        <div className="team__it-bl">
                                                            <img src={require("../assets/img/team-2.jpg")}/>
                                                            <div className="team__it-ct d-flex align-items-center">
                                                                <div className="team__it-ct-bl">
                                                                    <div className="team__it-name">
                                                                        Max <br/>
                                                                        Tadevosyan
                                                                    </div>
                                                                    <div className="team__it-position">
                                                                        Cofounder
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderContactsSection()}
                                <div className="section sc__footer fp-auto-height">
                                    <Footer showSbscrModal={this.showSbscrModal}/>
                                </div>
                            </>
                        )
                    }}
                />
                <Modals ref={instance => { this.modals = instance; }}/>
            </>

        )
    }
}
